import React from 'react'

interface Props {
  size?: number
}

export const SoldOutLight: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="96" viewBox="0 0 88 96" fill="none">
      <path
        d="M46.1455 96H41.8527C41.0625 96 40.4219 95.4904 40.4219 94.8618V61H47.5763V94.8618C47.5763 95.4904 46.9358 96 46.1455 96Z"
        fill="#A3A3A3"
      />
      <path d="M40.4219 59.2295H47.5763V66.7238H40.4219V59.2295Z" fill="#949494" />
      <path
        d="M80.8455 61H7.15447C3.20323 61 0 57.7968 0 53.8455V24.1545C0 20.2032 3.20323 17 7.15447 17H80.8455C84.7968 17 88 20.2032 88 24.1545V53.8455C88 57.7968 84.7968 61 80.8455 61Z"
        fill="#FF8799"
      />
      <path
        d="M80.8446 56.5655H7.15352C5.65373 56.5655 4.43359 55.3452 4.43359 53.8456V24.1545C4.43359 22.6547 5.65391 21.4346 7.15352 21.4346H80.8446C82.3444 21.4346 83.5645 22.6549 83.5645 24.1545V53.8456C83.5645 55.3453 82.3444 56.5655 80.8446 56.5655ZM7.15352 24.0127C7.07669 24.0127 7.01172 24.0777 7.01172 24.1545V53.8456C7.01172 53.9224 7.07669 53.9874 7.15352 53.9874H80.8446C80.9214 53.9874 80.9864 53.9224 80.9864 53.8456V24.1545C80.9864 24.0777 80.9214 24.0127 80.8446 24.0127H7.15352Z"
        fill="white"
      />
      <path
        d="M22.3739 46.978C21.3211 46.978 20.3121 46.7429 19.347 46.2727C18.9198 46.0646 18.5284 45.8306 18.1728 45.5707C17.4118 45.0144 17.4205 43.9398 18.0092 43.2037L18.218 42.9426C18.6103 42.4522 19.3527 42.4462 19.8503 42.8293C20.0703 42.9986 20.3046 43.1547 20.5534 43.2975C21.1822 43.6354 21.7817 43.8044 22.352 43.8044C22.8931 43.8044 23.3171 43.6722 23.6242 43.4077C23.9459 43.1286 24.1068 42.7759 24.1068 42.3499C24.1068 41.8356 23.902 41.4242 23.4926 41.1157C23.0832 40.8072 22.4324 40.4545 21.5404 40.0579C20.6923 39.6758 19.9977 39.3159 19.4567 38.978C18.9156 38.64 18.4477 38.1772 18.0529 37.5895C17.6727 36.9871 17.4826 36.2452 17.4826 35.3636C17.4826 34.5702 17.6873 33.843 18.0967 33.1818C18.5062 32.5207 19.0838 31.9991 19.8295 31.6171C20.5753 31.2204 21.4308 31.022 22.3959 31.022C23.361 31.022 24.2603 31.2277 25.0938 31.6391C25.3796 31.7728 25.6504 31.9181 25.9062 32.0751C26.7464 32.5906 26.8061 33.7183 26.2131 34.5056L26.0817 34.6801C25.7201 35.1602 25.0195 35.2085 24.5099 34.8898C24.3532 34.7918 24.1895 34.7 24.019 34.6143C23.5072 34.3352 23.01 34.1956 22.5275 34.1956C22.0449 34.1956 21.6501 34.3205 21.343 34.5702C21.0506 34.8053 20.9043 35.1212 20.9043 35.5179C20.9043 35.9587 21.1017 36.3333 21.4966 36.6419C21.906 36.9357 22.5275 37.2663 23.361 37.6336C24.2384 38.045 24.9476 38.427 25.4886 38.7796C26.0443 39.1175 26.5196 39.595 26.9144 40.2121C27.3238 40.8145 27.5285 41.5638 27.5285 42.4601C27.5285 43.2975 27.3238 44.0615 26.9144 44.7521C26.5049 45.4426 25.9054 45.9862 25.1158 46.3829C24.3407 46.7796 23.4268 46.978 22.3739 46.978Z"
        fill="white"
      />
      <path
        d="M37.179 47C35.6144 47 34.2252 46.64 33.0115 45.9201C31.8124 45.1855 30.8838 44.2084 30.2258 42.989C29.5678 41.7695 29.2387 40.4399 29.2387 39C29.2387 37.5601 29.5678 36.2305 30.2258 35.011C30.8838 33.7916 31.8124 32.8219 33.0115 32.1019C34.2252 31.3673 35.6144 31 37.179 31C38.7437 31 40.1255 31.3673 41.3246 32.1019C42.5383 32.8219 43.4742 33.7989 44.1322 35.0331C44.7903 36.2525 45.1193 37.5748 45.1193 39C45.1193 40.4399 44.7903 41.7695 44.1322 42.989C43.4742 44.2084 42.5383 45.1855 41.3246 45.9201C40.1255 46.64 38.7437 47 37.179 47ZM37.179 43.7824C38.0856 43.7824 38.8899 43.562 39.5918 43.1212C40.2937 42.6804 40.8348 42.0927 41.215 41.3581C41.6098 40.6235 41.8072 39.8375 41.8072 39C41.8072 38.1625 41.6098 37.3838 41.215 36.6639C40.8348 35.9293 40.2937 35.3416 39.5918 34.9008C38.8899 34.4601 38.0856 34.2397 37.179 34.2397C36.2724 34.2397 35.4681 34.4601 34.7662 34.9008C34.0643 35.3269 33.516 35.9073 33.1211 36.6419C32.7409 37.3765 32.5508 38.1625 32.5508 39C32.5508 39.8375 32.7409 40.6235 33.1211 41.3581C33.516 42.0927 34.0643 42.6804 34.7662 43.1212C35.4681 43.562 36.2724 43.7824 37.179 43.7824Z"
        fill="white"
      />
      <path
        d="M54.3981 43.584C55.2622 43.584 55.9628 44.2846 55.9628 45.1488C55.9628 46.0129 55.2622 46.7135 54.3981 46.7135H49.6277C48.5231 46.7135 47.6277 45.8181 47.6277 44.7135V32.8987C47.6277 32.0083 48.3495 31.2865 49.2399 31.2865C50.1303 31.2865 50.8521 32.0083 50.8521 32.8987V42.584C50.8521 43.1363 51.2998 43.584 51.8521 43.584H54.3981Z"
        fill="white"
      />
      <path
        d="M62.2352 31.2865C63.7999 31.2865 65.1671 31.6244 66.3369 32.3003C67.5214 32.9761 68.428 33.9017 69.0568 35.0771C69.6856 36.2378 70 37.5308 70 38.9559C70 40.3958 69.6856 41.7107 69.0568 42.9008C68.428 44.0762 67.5214 45.0092 66.3369 45.6997C65.1671 46.3756 63.7999 46.7135 62.2352 46.7135H59.7167C58.6121 46.7135 57.7167 45.8181 57.7167 44.7135V33.2865C57.7167 32.1819 58.6121 31.2865 59.7167 31.2865H62.2352ZM62.2791 43.584C63.1711 43.584 63.9461 43.3783 64.6041 42.9669C65.2768 42.5556 65.7886 41.9972 66.1395 41.292C66.5051 40.5868 66.6879 39.8081 66.6879 38.9559C66.6879 38.1038 66.5124 37.3324 66.1615 36.6419C65.8105 35.9513 65.2987 35.4077 64.6261 35.011C63.9534 34.6143 63.1711 34.416 62.2791 34.416C61.5401 34.416 60.9411 35.015 60.9411 35.754V42.246C60.9411 42.985 61.5401 43.584 62.2791 43.584Z"
        fill="white"
      />
      <path
        d="M31.135 12.9973C30.7161 12.9973 30.2955 12.8524 29.9542 12.5566L26.6229 9.66999C25.8697 9.01733 25.7881 7.87773 26.4408 7.12456C27.0932 6.37138 28.2328 6.28983 28.9862 6.94249L32.3175 9.82906C33.0707 10.4817 33.1523 11.6213 32.4996 12.3745C32.1426 12.7863 31.6402 12.9973 31.135 12.9973Z"
        fill="#ADADAD"
      />
      <path
        d="M39.1524 7.83912C38.3722 7.83912 37.6527 7.32918 37.4218 6.54258L36.18 2.31324C35.8993 1.35713 36.4467 0.35432 37.403 0.0736279C38.3589 -0.207301 39.362 0.340333 39.6426 1.29668L40.8844 5.52602C41.1651 6.48212 40.6177 7.48493 39.6614 7.76563C39.4919 7.81541 39.3207 7.83912 39.1524 7.83912Z"
        fill="#ADADAD"
      />
      <path
        d="M48.7013 7.83912C48.533 7.83912 48.3618 7.81541 48.1921 7.76563C47.2358 7.48493 46.6884 6.48213 46.9691 5.52602L48.2108 1.29668C48.4915 0.340333 49.4948 -0.207301 50.4504 0.0736279C51.4068 0.35432 51.9542 1.35713 51.6735 2.31324L50.4317 6.54258C50.201 7.32894 49.4815 7.83912 48.7013 7.83912Z"
        fill="#ADADAD"
      />
      <path
        d="M56.7409 12.9972C56.2357 12.9972 55.7331 12.7862 55.3763 12.3744C54.7237 11.6212 54.8052 10.4816 55.5584 9.82898L58.8897 6.94241C59.6434 6.28975 60.7827 6.37154 61.4351 7.12448C62.0878 7.87765 62.0062 9.01725 61.2531 9.66991L57.9218 12.5565C57.5806 12.8523 57.1598 12.9972 56.7409 12.9972Z"
        fill="#ADADAD"
      />
    </svg>
  )
}

export const SoldOutDark: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="96" viewBox="0 0 88 96" fill="none">
      <path
        d="M46.1455 96H41.8527C41.0625 96 40.4219 95.4904 40.4219 94.8618V61H47.5763V94.8618C47.5763 95.4904 46.9358 96 46.1455 96Z"
        fill="#7A6D79"
      />
      <path d="M40.4219 59.2295H47.5763V66.7238H40.4219V59.2295Z" fill="#685E68" />
      <path
        d="M80.8455 61H7.15447C3.20323 61 0 57.7968 0 53.8455V24.1545C0 20.2032 3.20323 17 7.15447 17H80.8455C84.7968 17 88 20.2032 88 24.1545V53.8455C88 57.7968 84.7968 61 80.8455 61Z"
        fill="#FF8799"
      />
      <path
        d="M80.8446 56.5655H7.15352C5.65373 56.5655 4.43359 55.3452 4.43359 53.8456V24.1545C4.43359 22.6547 5.65391 21.4346 7.15352 21.4346H80.8446C82.3444 21.4346 83.5645 22.6549 83.5645 24.1545V53.8456C83.5645 55.3453 82.3444 56.5655 80.8446 56.5655ZM7.15352 24.0127C7.07669 24.0127 7.01172 24.0777 7.01172 24.1545V53.8456C7.01172 53.9224 7.07669 53.9874 7.15352 53.9874H80.8446C80.9214 53.9874 80.9864 53.9224 80.9864 53.8456V24.1545C80.9864 24.0777 80.9214 24.0127 80.8446 24.0127H7.15352Z"
        fill="#2E3466"
      />
      <path
        d="M22.3739 46.978C21.3211 46.978 20.3121 46.7429 19.347 46.2727C18.9198 46.0646 18.5284 45.8306 18.1728 45.5707C17.4118 45.0144 17.4205 43.9398 18.0092 43.2037L18.218 42.9426C18.6103 42.4522 19.3527 42.4462 19.8503 42.8293C20.0703 42.9986 20.3046 43.1547 20.5534 43.2975C21.1822 43.6354 21.7817 43.8044 22.352 43.8044C22.8931 43.8044 23.3171 43.6722 23.6242 43.4077C23.9459 43.1286 24.1068 42.7759 24.1068 42.3499C24.1068 41.8356 23.902 41.4242 23.4926 41.1157C23.0832 40.8072 22.4324 40.4545 21.5404 40.0579C20.6923 39.6758 19.9977 39.3159 19.4567 38.978C18.9156 38.64 18.4477 38.1772 18.0529 37.5895C17.6727 36.9871 17.4826 36.2452 17.4826 35.3636C17.4826 34.5702 17.6873 33.843 18.0967 33.1818C18.5062 32.5207 19.0838 31.9991 19.8295 31.6171C20.5753 31.2204 21.4308 31.022 22.3959 31.022C23.361 31.022 24.2603 31.2277 25.0938 31.6391C25.3796 31.7728 25.6504 31.9181 25.9062 32.0751C26.7464 32.5906 26.8061 33.7183 26.2131 34.5056L26.0817 34.6801C25.7201 35.1602 25.0195 35.2085 24.5099 34.8898C24.3532 34.7918 24.1895 34.7 24.019 34.6143C23.5072 34.3352 23.01 34.1956 22.5275 34.1956C22.0449 34.1956 21.6501 34.3205 21.343 34.5702C21.0506 34.8053 20.9043 35.1212 20.9043 35.5179C20.9043 35.9587 21.1017 36.3333 21.4966 36.6419C21.906 36.9357 22.5275 37.2663 23.361 37.6336C24.2384 38.045 24.9476 38.427 25.4886 38.7796C26.0443 39.1175 26.5196 39.595 26.9144 40.2121C27.3238 40.8145 27.5285 41.5638 27.5285 42.4601C27.5285 43.2975 27.3238 44.0615 26.9144 44.7521C26.5049 45.4426 25.9054 45.9862 25.1158 46.3829C24.3407 46.7796 23.4268 46.978 22.3739 46.978Z"
        fill="#2E3466"
      />
      <path
        d="M37.179 47C35.6144 47 34.2252 46.64 33.0115 45.9201C31.8124 45.1855 30.8838 44.2084 30.2258 42.989C29.5678 41.7695 29.2387 40.4399 29.2387 39C29.2387 37.5601 29.5678 36.2305 30.2258 35.011C30.8838 33.7916 31.8124 32.8219 33.0115 32.1019C34.2252 31.3673 35.6144 31 37.179 31C38.7437 31 40.1255 31.3673 41.3246 32.1019C42.5383 32.8219 43.4742 33.7989 44.1322 35.0331C44.7903 36.2525 45.1193 37.5748 45.1193 39C45.1193 40.4399 44.7903 41.7695 44.1322 42.989C43.4742 44.2084 42.5383 45.1855 41.3246 45.9201C40.1255 46.64 38.7437 47 37.179 47ZM37.179 43.7824C38.0856 43.7824 38.8899 43.562 39.5918 43.1212C40.2937 42.6804 40.8348 42.0927 41.215 41.3581C41.6098 40.6235 41.8072 39.8375 41.8072 39C41.8072 38.1625 41.6098 37.3838 41.215 36.6639C40.8348 35.9293 40.2937 35.3416 39.5918 34.9008C38.8899 34.4601 38.0856 34.2397 37.179 34.2397C36.2724 34.2397 35.4681 34.4601 34.7662 34.9008C34.0643 35.3269 33.516 35.9073 33.1211 36.6419C32.7409 37.3765 32.5508 38.1625 32.5508 39C32.5508 39.8375 32.7409 40.6235 33.1211 41.3581C33.516 42.0927 34.0643 42.6804 34.7662 43.1212C35.4681 43.562 36.2724 43.7824 37.179 43.7824Z"
        fill="#2E3466"
      />
      <path
        d="M54.3981 43.584C55.2622 43.584 55.9628 44.2846 55.9628 45.1488C55.9628 46.0129 55.2622 46.7135 54.3981 46.7135H49.6277C48.5231 46.7135 47.6277 45.8181 47.6277 44.7135V32.8987C47.6277 32.0083 48.3495 31.2865 49.2399 31.2865C50.1303 31.2865 50.8521 32.0083 50.8521 32.8987V42.584C50.8521 43.1363 51.2998 43.584 51.8521 43.584H54.3981Z"
        fill="#2E3466"
      />
      <path
        d="M62.2352 31.2865C63.7999 31.2865 65.1671 31.6244 66.3369 32.3003C67.5214 32.9761 68.428 33.9017 69.0568 35.0771C69.6856 36.2378 70 37.5308 70 38.9559C70 40.3958 69.6856 41.7107 69.0568 42.9008C68.428 44.0762 67.5214 45.0092 66.3369 45.6997C65.1671 46.3756 63.7999 46.7135 62.2352 46.7135H59.7167C58.6121 46.7135 57.7167 45.8181 57.7167 44.7135V33.2865C57.7167 32.1819 58.6121 31.2865 59.7167 31.2865H62.2352ZM62.2791 43.584C63.1711 43.584 63.9461 43.3783 64.6041 42.9669C65.2768 42.5556 65.7886 41.9972 66.1395 41.292C66.5051 40.5868 66.6879 39.8081 66.6879 38.9559C66.6879 38.1038 66.5124 37.3324 66.1615 36.6419C65.8105 35.9513 65.2987 35.4077 64.6261 35.011C63.9534 34.6143 63.1711 34.416 62.2791 34.416C61.5401 34.416 60.9411 35.015 60.9411 35.754V42.246C60.9411 42.985 61.5401 43.584 62.2791 43.584Z"
        fill="#2E3466"
      />
      <path
        d="M31.135 12.9972C30.7161 12.9972 30.2955 12.8524 29.9542 12.5565L26.6229 9.66993C25.8697 9.01727 25.7881 7.87767 26.4408 7.1245C27.0932 6.37132 28.2328 6.28977 28.9862 6.94243L32.3175 9.829C33.0707 10.4817 33.1523 11.6213 32.4996 12.3744C32.1426 12.7862 31.6402 12.9972 31.135 12.9972Z"
        fill="#646AB7"
      />
      <path
        d="M39.1524 7.83912C38.3722 7.83912 37.6527 7.32918 37.4218 6.54258L36.18 2.31324C35.8993 1.35713 36.4467 0.35432 37.403 0.0736279C38.3589 -0.207301 39.362 0.340333 39.6426 1.29668L40.8844 5.52602C41.1651 6.48212 40.6177 7.48493 39.6614 7.76563C39.4919 7.81541 39.3207 7.83912 39.1524 7.83912Z"
        fill="#646AB7"
      />
      <path
        d="M48.7013 7.83912C48.533 7.83912 48.3618 7.81541 48.1921 7.76563C47.2358 7.48493 46.6884 6.48213 46.9691 5.52602L48.2108 1.29668C48.4915 0.340333 49.4948 -0.207301 50.4504 0.0736279C51.4068 0.35432 51.9542 1.35713 51.6735 2.31324L50.4317 6.54258C50.201 7.32894 49.4815 7.83912 48.7013 7.83912Z"
        fill="#646AB7"
      />
      <path
        d="M56.7409 12.9971C56.2357 12.9971 55.7331 12.7861 55.3763 12.3744C54.7237 11.6212 54.8052 10.4816 55.5584 9.82892L58.8897 6.94235C59.6434 6.28969 60.7827 6.37148 61.4351 7.12442C62.0878 7.87759 62.0062 9.01719 61.2531 9.66985L57.9218 12.5564C57.5806 12.8523 57.1598 12.9971 56.7409 12.9971Z"
        fill="#646AB7"
      />
    </svg>
  )
}
