import { useTranslation } from 'contexts/Localization'
import { Box } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import styled from 'styled-components'
import { Chain } from 'config/constants/types'
import networkParams from 'config/constants/networkParams'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

const Content = styled.div`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries?.md} {
    margin-bottom: 84px;
  }
`

const Modal = styled(ModalV2)`
  span {
    text-transform: unset;
  }

  & > div:last-child {
    overflow-y: auto;
    max-height: calc(80vh - 83px);

    ${({ theme }) => theme.mediaQueries?.md} {
      max-height: calc(80vh - 87px);
    }

    & > div:first-child {
      max-height: unset;
    }

    & > div:last-child {
      position: absolute;
      bottom: 0;
      padding: 40px 0;
      background: ${({ theme }) => theme.modal.background};
      width: calc(100% - 40px);

      ${({ theme }) => theme.mediaQueries?.md} {
        width: calc(100% - 80px);
      }
    }
  }
`

interface PurchaseCardDisclaimerProps {
  onDismiss?: VoidFunction
  purchaseHandler: VoidFunction
  saleID: string
  chainID: Chain
}

export const localStorageKey = (saleID: string) => `DISCLAIMER_AGREED_${saleID}`

const PurchaseCardDisclaimer: React.FC<PurchaseCardDisclaimerProps> = ({
  onDismiss,
  purchaseHandler,
  saleID,
  chainID,
}) => {
  const { t } = useTranslation()
  const { brand, mainUrl } = useContext(CompanyContext)
  const onCanfirm = useCallback(() => {
    localStorage.setItem(localStorageKey(saleID), 'true')
    purchaseHandler()
  }, [purchaseHandler, saleID])

  const isAgreed = localStorage.getItem(localStorageKey(saleID)) != null
  if (isAgreed) {
    purchaseHandler()
    return null
  }

  const chainName = networkParams[chainID]?.[0]?.chainName || 'chain'

  return (
    <Modal title={t('Disclaimer')} confirmText={t('Agree')} showConfirm onDismiss={onDismiss} onConfirm={onCanfirm}>
      <Content>
        {brand === Brands.IF ? (
          <IFTypography variant="button" lineHeight="20px" ifcolor="text">
            {t(
              `Impossible Finance is a decentralized peer-to-peer protocol that people can use to create liquidity and trade tokens. There are multiple versions of the Impossible Finance protocol, each of which is made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on %chainName%. Your use of the Impossible Finance protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the Impossible Finance protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Impossible Finance protocol, you should review the relevant documentation to make sure you understand how the Impossible Finance protocol works. Additionally, just as you can access email protocols, such as SMTP, through multiple email clients, you can access the Impossible Finance protocol through multiple web and mobile interfaces. You are responsible for conducting your own diligence on those interfaces to understand the fees and risks involved.`,
              {
                chainName,
              },
            )}
          </IFTypography>
        ) : (
          <IFTypography variant="button" lineHeight="20px" ifcolor="text">
            {t(
              `The service on %mainUrl% (“Service”) is provided by Impossible Finance, which is a decentralized peer-to-peer protocol that people can use to create liquidity and trade tokens. There are multiple versions of the Impossible Finance protocol, each of which is made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on %chainName%. Your use of the Service involves various risks, including, but not limited to, losses while digital assets are being supplied to the Services or the Impossible Finance protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Service or Impossible Finance protocol, you should review the relevant documentation to make sure you understand how the Service and the Impossible Finance protocol work. Additionally, just as you can access email protocols, such as SMTP, through multiple email clients, you can access the Service and the Impossible Finance protocol through multiple web and mobile interfaces. You are responsible for conducting your own diligence on those interfaces to understand the fees and risks involved.`,
              {
                mainUrl,
                chainName,
              },
            )}
          </IFTypography>
        )}

        <Box marginTop="16px" />
        {brand === Brands.IF ? (
          <IFTypography variant="button" lineHeight="20px" ifcolor="text">
            {t(
              `THE IMPOSSIBLE FINANCE PROTOCOL IS PROVIDED ”AS IS” AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. The Impossible Finance protocol is run by smart contracts deployed on the %chainName%. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the Impossible Finance  governance token. No developer or entity involved in creating the Impossible Finance protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of the Impossible Finance protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`,
              {
                chainName,
              },
            )}
          </IFTypography>
        ) : (
          <IFTypography variant="button" lineHeight="20px" ifcolor="text">
            {t(
              `THE SERVICES AND THE IMPOSSIBLE FINANCE PROTOCOL ARE PROVIDED ”AS IS” AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. The Services and the Impossible Finance protocol are run by smart contracts deployed on the %chainName%. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the Impossible Finance governance token. No developer or entity involved in creating the Services or the Impossible Finance protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of the Services or the Impossible Finance protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`,
              {
                chainName,
              },
            )}
          </IFTypography>
        )}
      </Content>
    </Modal>
  )
}

export default PurchaseCardDisclaimer
