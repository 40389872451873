import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAddressState } from 'state/user/actions'
import { useAccount } from 'wagmi'
import { SerializedAddressState } from 'config/constants/types'
import { AppState } from '../state'

const useFetchUserState = () => {
  const { address: account } = useAccount()
  const dispatch = useDispatch()
  const addressState = useSelector<AppState, SerializedAddressState>((state) => state.user.addressState)

  const onFetchAddressState = async () => {
    if (account) {
      const URL = process.env.REACT_APP_BACKEND_URL
      const ENDPOINT = `${URL}/api/backend-service/address/${account}/status`

      try {
        const response = await fetch(`${ENDPOINT}`, { headers: [['Cache-Control', 'no-cache']] })
        const responseData = await response.json()
        dispatch(
          setAddressState({
            serializedAddressState: {
              ...addressState,
              [account]: {
                state: responseData.status,
                country: responseData.country,
              },
            },
          }),
        )
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }
  }

  useEffect(() => {
    if (account) {
      onFetchAddressState()
    }
  }, [account])
}

export default useFetchUserState
