// Step 4 Add New Chains

import { Chain } from 'config/constants/types'
import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = {
  [Chain.BSC_MAINNET]: ['https://bsc-dataseed.binance.org'],
  [Chain.BSC_TESTNET]: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
  [Chain.AVAX_MAINNET]: ['https://api.avax.network/ext/bc/C/rpc'],
  [Chain.ETH_MAINNET]: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  [Chain.MOONRIVER_MAINNET]: ['https://rpc.api.moonriver.moonbeam.network'],
  [Chain.MOONBEAM_MAINNET]: ['https://rpc.api.moonbeam.network'],
  [Chain.AURORA_MAINNET]: ['https://mainnet.aurora.dev'],
  [Chain.GOERLI]: ['https://rpc.ankr.com/eth_goerli'],
  [Chain.ARBITRUM_MAINNET]: [
    'https://arbitrum-one.public.blastapi.io',
    'https://1rpc.io/arb',
    'https://arb1.arbitrum.io/rpc',
  ],
  [Chain.ARBITRUM_SEPOLIA]: ['https://arbitrum-sepolia.blockpi.network/v1/rpc/public'],
  [Chain.OPTIMISM_MAINNET]: ['https://mainnet.optimism.io'],
  [Chain.NEON_DEVNET]: ['https://proxy.devnet.neonlabs.org/solana'],
  [Chain.OKC_MAINNET]: ['https://exchainrpc.okex.org'],
  [Chain.OMNI_TESTNET]: ['https://testnet.omni.network/'],
  [Chain.ARBITRUM_GOERLI]: ['https://arbitrum-goerli.publicnode.com'],
  [Chain.LINEA_TESTNET]: ['https://rpc.goerli.linea.build'],
  [Chain.POLYGON_MAINNET]: ['https://rpc-mainnet.maticvigil.com'],
  [Chain.MANTLE]: ['https://rpc-tob.mantle.xyz/v1/ZDc4NjJlNTBkYTEyZjc4YzU5OTQ4OGZl'],
  [Chain.HUMANODE_TESTNET]: ['https://explorer-rpc-http.it6.stages.humanode.io'],
  [Chain.RONIN]: ['https://api.roninchain.com/rpc'],
  [Chain.SAIGON]: ['https://saigon-testnet.roninchain.com/rpc'],
  [Chain.HUMANODE_MAINNET]: ['https://explorer-rpc-http.mainnet.stages.humanode.io'],
  [Chain.MANTA_MAINNET]: ['https://pacific-rpc.manta.network/http'],
  [Chain.OKX_TESTNET]: ['https://testrpc.x1.tech'],
  [Chain.BASE_SEPOLIA]: ['https://sepolia.base.org'],
}

const getNodeUrl = (chainId) => {
  return sample(nodes[chainId])
}

export default getNodeUrl
