import React from 'react'
import styled from 'styled-components'

const SouthArrowSVG = styled.svg`
  fill: ${({ theme }) => theme.colorsV2?.text};
`

export const SouthArrow = () => (
  <SouthArrowSVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 15L17.59 13.59L13 18.17V2H11V18.17L6.41 13.58L5 15L12 22L19 15Z" />
  </SouthArrowSVG>
)

const AddToWalletSVG = styled.svg`
  fill: ${({ theme }) => theme.colorsV2?.text};
`

export const AddToWallet = () => (
  <AddToWalletSVG width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3457 3.9375C11.035 3.9375 10.7832 4.18934 10.7832 4.5C10.7832 4.81066 11.035 5.0625 11.3457 5.0625H14.7207C14.8243 5.0625 14.9082 5.14645 14.9082 5.25V6.9375H15.8457C15.9104 6.9375 15.9733 6.9457 16.0332 6.96113V5.25C16.0332 4.52513 15.4456 3.9375 14.7207 3.9375H11.3457Z"
      fillOpacity="0.7"
    />
    <path
      d="M16.0332 11.4139C15.9733 11.4293 15.9104 11.4375 15.8457 11.4375H14.9082V13.125C14.9082 13.2286 14.8243 13.3125 14.7207 13.3125H4.5957C4.49215 13.3125 4.4082 13.2286 4.4082 13.125V12.375C4.4082 12.0643 4.15636 11.8125 3.8457 11.8125C3.53504 11.8125 3.2832 12.0643 3.2832 12.375V13.125C3.2832 13.8499 3.87083 14.4375 4.5957 14.4375H14.7207C15.4456 14.4375 16.0332 13.8499 16.0332 13.125V11.4139Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4707 8.0625H12.8457C12.2244 8.0625 11.7207 8.56618 11.7207 9.1875C11.7207 9.80882 12.2244 10.3125 12.8457 10.3125H15.4707V8.0625ZM12.8457 6.9375C11.6031 6.9375 10.5957 7.94486 10.5957 9.1875C10.5957 10.4301 11.6031 11.4375 12.8457 11.4375H15.8457C16.2599 11.4375 16.5957 11.1017 16.5957 10.6875V7.6875C16.5957 7.27329 16.2599 6.9375 15.8457 6.9375H12.8457Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9707 9C6.62756 9 7.9707 7.65685 7.9707 6C7.9707 4.34315 6.62756 3 4.9707 3C3.31385 3 1.9707 4.34315 1.9707 6C1.9707 7.65685 3.31385 9 4.9707 9ZM4.9707 10.125C7.24888 10.125 9.0957 8.27817 9.0957 6C9.0957 3.72183 7.24888 1.875 4.9707 1.875C2.69253 1.875 0.845703 3.72183 0.845703 6C0.845703 8.27817 2.69253 10.125 4.9707 10.125Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.0957 6C3.0957 5.68934 3.34754 5.4375 3.6582 5.4375H6.2832C6.59386 5.4375 6.8457 5.68934 6.8457 6C6.8457 6.31066 6.59386 6.5625 6.2832 6.5625H3.6582C3.34754 6.5625 3.0957 6.31066 3.0957 6Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9707 4.125C5.28136 4.125 5.5332 4.37684 5.5332 4.6875V7.3125C5.5332 7.62316 5.28136 7.875 4.9707 7.875C4.66004 7.875 4.4082 7.62316 4.4082 7.3125V4.6875C4.4082 4.37684 4.66004 4.125 4.9707 4.125Z"
      fillOpacity="0.7"
    />
  </AddToWalletSVG>
)

const BellIconSVG = styled.svg`
  fill: ${({ theme }) => (theme.isDark ? theme.colorsV2?.text : theme.colorsV2.infoCard.infoText)};
`

export const BellIcon = (props) => (
  <BellIconSVG width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.33399 15.3333C9.12238 15.3333 9.76189 14.6992 9.76189 13.9167H6.90609C6.90609 14.6992 7.5456 15.3333 8.33399 15.3333ZM13.1418 11.7585C11.1326 9.61755 12.7358 5.92937 9.04783 5.16964V4.70833C9.04783 4.3172 8.72819 4 8.33399 4C7.9398 4 7.62015 4.3172 7.62015 4.70833V5.16964C3.93216 5.92937 5.53534 9.61755 3.52619 11.7585C3.39226 11.9012 3.33288 12.0719 3.334 12.239C3.33646 12.6021 3.62373 12.9474 4.05052 12.9474H12.6175C13.0442 12.9474 13.3317 12.6021 13.334 12.239C13.3351 12.0719 13.2757 11.901 13.1418 11.7585Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4417 3.17476C10.4642 2.97253 10.6464 2.82681 10.8486 2.84928C11.4803 2.91947 12.6916 3.51123 13.1566 5.19218C13.2108 5.38829 13.0958 5.59124 12.8997 5.64548C12.7036 5.69973 12.5006 5.58472 12.4464 5.38861C12.0651 4.01002 11.112 3.61992 10.7672 3.58161C10.565 3.55914 10.4193 3.37699 10.4417 3.17476Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9163 1.70015C11.9388 1.49792 12.121 1.3522 12.3232 1.37467C12.9549 1.44486 14.1662 2.03662 14.6312 3.71757C14.6854 3.91368 14.5704 4.11663 14.3743 4.17087C14.1782 4.22512 13.9753 4.11011 13.921 3.914C13.5397 2.53541 12.5866 2.14531 12.2418 2.107C12.0396 2.08454 11.8939 1.90238 11.9163 1.70015Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.7985 3.17476C5.77603 2.97253 5.59387 2.82681 5.39164 2.84928C4.75996 2.91947 3.5486 3.51123 3.08366 5.19218C3.02942 5.38829 3.14442 5.59124 3.34053 5.64548C3.53664 5.69973 3.73959 5.58472 3.79384 5.38861C4.17515 4.01002 5.12825 3.61992 5.47301 3.58161C5.67524 3.55914 5.82097 3.37699 5.7985 3.17476Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.32584 1.70015C4.30337 1.49792 4.12122 1.3522 3.91899 1.37467C3.2873 1.44486 2.07595 2.03662 1.611 3.71757C1.55676 3.91368 1.67176 4.11663 1.86787 4.17087C2.06398 4.22512 2.26694 4.11011 2.32118 3.914C2.70249 2.53541 3.6556 2.14531 4.00036 2.107C4.20259 2.08454 4.34831 1.90238 4.32584 1.70015Z"
    />
  </BellIconSVG>
)
