import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { FixedAllocationIDO, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import { PurchaseCard, PurchaseStartCard, StakingMissedCard } from 'componentsV2/Card'
import { useIsSaleUserDataFetched, useSaleUserData } from 'state/idos/hooks'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import { isSubscribeableIDO } from 'state/idos/saleUtil'
import LoadingCard from 'componentsV2/Card/LoadingCard/LoadingCard'
import { convertFromWei } from 'utils/formatBalance'
import SoldOutCard from 'componentsV2/Card/SoldOutCard/SoldOutCard'
import { useGetAllocation } from 'hooks/useIFASale'

const Purchasing: React.FC<{
  sale: PurchasableIDO
}> = ({ sale }) => {
  const userData = useSaleUserData(sale.id)
  const { address: account } = useAccount()

  const isSaleUserDataFetched = useIsSaleUserDataFetched()
  const { allo: userAllocationInWei, isLoading } = useGetAllocation(sale.id)

  const getPurchasingState = useCallback(() => {
    const now = new Date()
    const { purchasePeriod, token, paymentToken, chainId, stepRequirement } = sale
    const { image } = token
    const { startTime, salePrice, maxTotalPurchasable, totalPaymentReceived, publicAllocation } = purchasePeriod

    const userAllocation = new BigNumber(publicAllocation).isGreaterThan(0)
      ? convertFromWei(new BigNumber(publicAllocation), token.decimals)
      : userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), token.decimals)

    const estimatedAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)
    const userStakeWeight = new BigNumber(userData?.userStakeWeight ?? 0)

    const totalPurchasedAllocation = new BigNumber(totalPaymentReceived).dividedBy(salePrice)
    const hasTotalMaxPurchase = maxTotalPurchasable > 0

    const isSoldOut = hasTotalMaxPurchase && maxTotalPurchasable === totalPurchasedAllocation.toNumber()

    if (
      !(sale as unknown as FixedAllocationIDO).saleTokenAllocationOverride &&
      isSubscribeableIDO(sale as unknown as SubscribeableIDO) &&
      userStakeWeight.isEqualTo(0)
    ) {
      return <StakingMissedCard stepRequirement={stepRequirement} />
    }

    if (new Date(startTime) > now) {
      return (
        <PurchaseStartCard
          stepRequirement={stepRequirement}
          tokenImage={image}
          estimatedAllocation={estimatedAllocation}
          projectPrice={new BigNumber(salePrice)}
          endTime={startTime}
          paymentToken={paymentToken}
          chainId={chainId}
        />
      )
    }

    if (isSoldOut) {
      return <SoldOutCard sale={sale} projectImage={image} />
    }

    if (userAllocation.isEqualTo(0)) {
      return <LoadingCard />
    }

    return (
      <PurchaseCard
        sale={sale}
        projectImage={image}
        userAllocationInWei={
          new BigNumber(publicAllocation).isGreaterThan(0) ? publicAllocation.toString() : userAllocationInWei
        }
      />
    )
  }, [sale, userData, userAllocationInWei])

  if ((account && !isSaleUserDataFetched) || isLoading) {
    return <LoadingCard />
  }

  return <Box>{getPurchasingState()}</Box>
}

export default Purchasing
