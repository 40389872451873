import { Box, BoxProps } from '@mui/material'
import { useAccount } from 'wagmi'
import BigNumber from 'bignumber.js'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'
// import AnimatedCheckedBox from 'componentsV2/AnimatedCheckedBox'
import { AnimatedCheckedBox } from 'componentsV2'
import { ClosedDark, ClosedLight } from 'componentsV2/Icons/Sale/Closed'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Chain } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import { useWithdrawV2 } from 'hooks/useIFASale'
import React, { useCallback, useContext, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { LinkExternal, useMatchBreakpoints, useModalV2 } from 'uikit'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import { getBscScanLink, getScanText } from 'utils'
import { registerToken } from 'utils/wallet'
import { StepRequirementEnum } from 'state/types'
import SaleDetailCard from '../SaleDetailCard'

const SVGWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.colorsV2?.textLink} !important;
  }
`

const PurchasedAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
`

const ViewOnBscscanLinkBtn = styled.div`
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
`

interface TokenClaimedModalProps {
  handler: () => Promise<void>
  tokenSymbol: string
  amount: string
  onDismiss?: VoidFunction
  tokenAddress: string
  chainId: Chain
}

const TokenClaimedModal: React.FC<TokenClaimedModalProps> = ({
  amount,
  handler,
  tokenSymbol,
  onDismiss,
  tokenAddress,
  chainId,
}) => {
  const { t } = useTranslation()
  const { showAddToWallet } = useContext(CompanyContext)

  const onConfirm = useCallback(async () => {
    await handler()
    onDismiss()
  }, [handler, onDismiss])

  return (
    <ModalV2
      bodyTitle={t('Token Claimed')}
      bodySubtitle={t('%amount% %token% has been sent to your wallet.', {
        amount,
        token: tokenSymbol,
      })}
      bodyDescription={
        <ViewOnBscscanLinkBtn>
          <SVGWrapper>
            <LinkExternal small href={getBscScanLink(tokenAddress, 'address', chainId)} mr="16px">
              {t('View on %scanner%', { scanner: getScanText(chainId) })}
            </LinkExternal>
          </SVGWrapper>
        </ViewOnBscscanLinkBtn>
      }
      bodyImage={<AnimatedCheckedBox />}
      bodyImageWidth={60}
      bodyImageMargin="0 0 57px 0"
      showConfirm={showAddToWallet}
      confirmText={t('Add %token% to your wallet', { token: tokenSymbol || '' })}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  )
}

interface ClaimCardProps {
  tokenSymbol: string
  tokenIcon: string
  tokenAddress: string
  tokenDecimals?: number
  salePrice?: number
  purchasedAmount: number | BigNumber
  saleAddress: string
  chainId?: Chain
  isPurchased?: boolean
  whitelistedUrl?: string
  stepRequirement?: StepRequirementEnum[]
  userAllocation?: string
  saleId?: string
}

const ClaimCard: React.FC<ClaimCardProps> = ({
  tokenIcon,
  tokenSymbol,
  tokenAddress,
  tokenDecimals = 18,
  purchasedAmount,
  saleAddress,
  salePrice,
  chainId,
  isPurchased,
  whitelistedUrl,
  stepRequirement,
  userAllocation,
  saleId,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { onWithdraw, isLoading } = useWithdrawV2(saleAddress, salePrice, whitelistedUrl, userAllocation, saleId)

  const isAllChains = chainId === 165 || chainId === 59140 || chainId === 421613
  const { address: account } = useAccount()

  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const amount = useMemo(() => {
    return new BigNumber(purchasedAmount).decimalPlaces(2).toNumber().toLocaleString()
  }, [purchasedAmount])

  const [onPresentLoadingModal, onDismiss] = useModalV2({
    modal: (
      <ModalV2
        bodyTitle={t('Confirm Claiming')}
        bodySubtitle={t('Claiming %amount% %token%.', {
          amount,
          token: tokenSymbol,
        })}
        bodyDescription={t('Confirm this transaction in your wallet')}
        showLoader
        bodyImageWidth={36}
        bodyImageMargin="33px 0 66px 0"
      />
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const addToWalletHandler = useCallback(async () => {
    await registerToken(tokenAddress, tokenSymbol, tokenDecimals)
  }, [tokenAddress, tokenSymbol, tokenDecimals])

  const [onPresentAddTokenToWalletModal] = useModalV2({
    modal: (
      <TokenClaimedModal
        tokenAddress={tokenAddress}
        tokenSymbol={tokenSymbol}
        amount={amount}
        handler={addToWalletHandler}
        chainId={chainId}
      />
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <ClosedDark /> : <ClosedLight />,
    }
  }, [theme])

  const title = t('Thank you for your participation.')

  const child = useMemo(() => {
    return (
      <PurchasedAmountWrapper>
        <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
          {isPurchased ? t('Your purchased tokens') : t('Your Total Share')} {isAllChains && '(all chains included)'}
        </IFTypography>
        <Box display="flex" alignContent="center">
          <Box marginRight="10px">
            <img src={tokenIcon} alt="token_icon" width="36px" />
          </Box>
          <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
            {amount}
          </IFTypography>
        </Box>
      </PurchasedAmountWrapper>
    )
  }, [tokenIcon, amount, t, isPurchased])

  const ctaInfo = useMemo(() => {
    const btnProps = {
      isLoading,
      disabled: isLoading,
      width: '100%',
    } as BoxProps
    if (!isMobile) {
      btnProps.maxWidth = '320px'
    }
    return {
      btnProps,
      text: t('Claim'),
      handler: async () => {
        onPresentLoadingModal()
        await onWithdraw(
          () => {
            onDismiss()
            onPresentAddTokenToWalletModal()
          },
          () => {
            onDismiss()
          },
        )
      },
      button: account == null ? <ConnectWalletButton width="100%" marginTop="20px" /> : null,
    }
  }, [isLoading, isMobile, t, account, onPresentLoadingModal, onWithdraw, onDismiss, onPresentAddTokenToWalletModal])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={chainId}
      imageInfo={imageInfo}
      title={title}
      // @ts-ignore
      ctaInfo={ctaInfo}
    >
      {child}
    </SaleDetailCard>
  )
}

export default ClaimCard
