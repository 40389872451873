import React from 'react'
import {
  FreeStakeDropConfig,
  PrivateIDOConfig,
  AuctionIDOConfig,
  PaidStakeSaleConfig,
  IDO,
  PurchasableIDO,
} from 'state/v2_types'
import styled from 'styled-components'
import ColorBorderBlankCard from '../ColorBorderBlankCard'
import Header from './Header'
import ParticipatedBadge from './ParticipatedBadge'
import TopTitle from './TopTitle'
import { SaleFiltered } from 'views/Project/Project'

const Container = styled(ColorBorderBlankCard)`
  max-width: 335px;
  align-self: center;
  padding: 40px 30px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:last-child {
    flex-grow: 1;
  }
`

interface SaleDetailCardMobileProps {
  participated: boolean
  borderColor: string
  showBorder: boolean
  sale: FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig | PaidStakeSaleConfig | SaleFiltered
}

const SaleBriefCardMobile: React.FC<SaleDetailCardMobileProps> = ({ participated, borderColor, showBorder, sale }) => {
  return (
    <Container borderColor={borderColor} showBorder={showBorder}>
      {participated && <ParticipatedBadge />}
      <TopTitle sale={sale} />
      <Header sale={sale} isMobile />
    </Container>
  )
}

export default SaleBriefCardMobile
