import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import Countdown from 'componentsV2/Countdown/Countdown'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import { useSaleUserData } from 'state/idos/hooks'
import { isDroppedIDO, isFixedAllocationIDO, isPurchaseableIDO, isSubscribeableIDO } from 'state/idos/saleUtil'
import { isSaleParticipated } from 'state/idos/utils'
import { DroppedIDO, FixedAllocationIDO, IDO, PayableIDO, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { convertFromWei } from 'utils/formatBalance'
import { formatAmount } from './helper'
import { useGetAllocation } from 'hooks/useIFASale'

interface HeaderProps {
  sale: IDO
  isMobile?: boolean
}

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '30px')};
  align-items: ${({ isMobile }) => (isMobile ? 'start' : 'normal')};

  & > div {
    display: flex;
    justify-content: space-between;

    & > span {
      padding-right: ${({ isMobile }) => (isMobile ? '15px' : '0px')};
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:not(:first-child) {
      height: 20px;
    }
  }
`

const SubscriptionNotStart: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const stakingStartTimeInSeconds = Math.trunc(
    (new Date(sale.subscribePeriod.startTime).getTime() - new Date().getTime()) / 1000,
  )
  const [descInactiveStyle, countdownInactiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])

  return (
    <Box marginBottom="50px" alignItems="center">
      <IFTypography variant="button" ifcolor="textDisabled" fontWeight="bold" fontFamily="Roboto">
        {t('Staking')}
      </IFTypography>
      <Countdown
        seconds={stakingStartTimeInSeconds}
        description={t('Starts in')}
        descriptionStyle={descInactiveStyle}
        style={countdownInactiveStyle}
        descriptionPosition="LEFT"
      />
    </Box>
  )
}

const SubscriptionStartNotEnd: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    subscribePeriod: { totalStaked, endTime },
    token: { symbol: idoTokenSymbol },
    stakingToken: { symbol: stakingTokenSymbol },
    id,
    chainId,
    saleTokenDefaultName,
  } = sale
  const userData = useSaleUserData(id)

  // OMNI OVERDRIVE
  const isAllChains = chainId === 165 || chainId === 59140 || chainId === 421613

  const participated = isSaleParticipated(sale, userData)
  const stakingEndTimeInSeconds = Math.trunc((new Date(endTime).getTime() - new Date().getTime()) / 1000)
  const totalStakedStr = new BigNumber(totalStaked ?? 0).decimalPlaces(2).toNumber().toLocaleString()
  const estimatedAllocation = userData?.userTokenAllocation || new BigNumber(0)
  const estimatedAllocationStr = estimatedAllocation.decimalPlaces(2).toNumber().toLocaleString()

  const [descActiveStyle, countdownActiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.textThirdly,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textThirdly,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])
  return (
    <>
      <Box alignItems="center">
        <IFTypography variant="button" ifcolor="textThirdly" fontWeight="bold" fontFamily="Roboto">
          ● {t('Staking')}
        </IFTypography>
        <Countdown
          seconds={stakingEndTimeInSeconds}
          description={t('Ends in')}
          descriptionStyle={descActiveStyle}
          style={countdownActiveStyle}
          descriptionPosition="LEFT"
        />
      </Box>
      {participated ? (
        <>
          {estimatedAllocation.isFinite() && (
            <Box>
              <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
                {t('EST. Allocation')} {isAllChains && '(all chains)'}
              </IFTypography>
              <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
                {estimatedAllocationStr} {idoTokenSymbol || saleTokenDefaultName}
              </IFTypography>
            </Box>
          )}
          <Box>
            <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
              {t('Total Staked')} {isAllChains && '(all chains)'}
            </IFTypography>
            <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
              {totalStakedStr} {stakingTokenSymbol}
            </IFTypography>
          </Box>
        </>
      ) : (
        <Box marginBottom="25px">
          <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
            {t('Total Staked')}
          </IFTypography>
          <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
            {totalStakedStr} {stakingTokenSymbol}
          </IFTypography>
        </Box>
      )}
    </>
  )
}

const PurchaseNotEnd: React.FC<{ sale: PurchasableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    purchasePeriod: { startTime, endTime, salePrice },
    token: { symbol: idoTokenSymbol, decimals: tokenDecimals },
    id,
    saleTokenDefaultName,
  } = sale
  const { allo: userAllocationInWei } = useGetAllocation(sale.id)
  const userData = useSaleUserData(id)
  const paymentReceivedInWei = userData?.paymentReceivedInWei
  const paymentReceived = convertFromWei(paymentReceivedInWei || new BigNumber(0))
  const purchasedAmount = paymentReceived.dividedBy(salePrice)
  const purchaseStartTimeInSeconds = Math.trunc((new Date(startTime).getTime() - new Date().getTime()) / 1000)
  const purchaseEndTimeInSeconds = Math.trunc((new Date(endTime).getTime() - new Date().getTime()) / 1000)
  const purchaseStarted = purchaseStartTimeInSeconds < 0
  const isSubscribeable = isSubscribeableIDO(sale as IDO as SubscribeableIDO)
  const isFixedAllocation = isFixedAllocationIDO(sale as IDO as FixedAllocationIDO)
  const hasPurchased = paymentReceived.isGreaterThan(0)

  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), tokenDecimals)
  const estimatedAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  const [descInactiveStyle, countdownInactiveStyle, descActiveStyle, countdownActiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
      {
        color: theme.colorsV2?.textThirdly,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textThirdly,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])
  return (
    <>
      <Box marginBottom={!isSubscribeable && !purchaseStarted ? '50px' : '0'} alignItems="center">
        <IFTypography
          variant="button"
          ifcolor={isSubscribeable || purchaseStarted ? 'textThirdly' : 'textDisabled'}
          fontWeight="bold"
          fontFamily="Roboto"
        >
          ● {t('Purchase')}
        </IFTypography>
        <Countdown
          seconds={purchaseStarted ? purchaseEndTimeInSeconds : purchaseStartTimeInSeconds}
          description={purchaseStarted ? t('Ends in') : t('Starts in')}
          descriptionStyle={isSubscribeable || purchaseStarted ? descActiveStyle : descInactiveStyle}
          style={isSubscribeable || purchaseStarted ? countdownActiveStyle : countdownInactiveStyle}
          descriptionPosition="LEFT"
        />
      </Box>
      {isSubscribeable ||
        (isFixedAllocation && (
          <Box marginBottom="25px">
            <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
              {t(hasPurchased ? 'Total Purchased' : 'Total Allocation')}
            </IFTypography>
            <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
              {formatAmount(
                hasPurchased ? purchasedAmount : estimatedAllocation,
                idoTokenSymbol || saleTokenDefaultName,
              )}
            </IFTypography>
          </Box>
        ))}
    </>
  )
}

const Closed: React.FC<{ sale: IDO; isMobile?: boolean }> = ({ sale, isMobile = false }) => {
  const { t } = useTranslation()
  const {
    id,
    token: { symbol: idoTokenSymbol, decimals },
    saleTokenDefaultName,
  } = sale
  const { allo: userAllocationInWei } = useGetAllocation(sale.id)
  const { paymentToken } = sale as unknown as PayableIDO
  const userData = useSaleUserData(id)
  const paymentReceivedInWei = userData?.paymentReceivedInWei
  const paymentReceived = convertFromWei(paymentReceivedInWei || new BigNumber(0), paymentToken.decimals)
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)
  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), decimals)
  const estimatedAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  let purchasedAmount = new BigNumber(0)
  let airdroppedAmount = new BigNumber(0)
  let isAirdropped = false

  if (isPurchaseable) {
    purchasedAmount = paymentReceived.dividedBy((sale as PurchasableIDO).purchasePeriod.salePrice)
  } else {
    purchasedAmount = estimatedAllocation
  }

  if (isDroppedIDO(sale as DroppedIDO)) {
    const now = new Date()
    const {
      airdropInfo: { finalAirdrop, firstDayRelease, initialAirdrop },
    } = sale as DroppedIDO

    if (finalAirdrop && now >= new Date(finalAirdrop)) {
      airdroppedAmount = isPurchaseable ? purchasedAmount : estimatedAllocation
      isAirdropped = true
    } else if (initialAirdrop && now >= new Date(initialAirdrop)) {
      airdroppedAmount = (isPurchaseable ? purchasedAmount : estimatedAllocation)
        .multipliedBy(parseFloat(firstDayRelease))
        .div(100)
      isAirdropped = true
    }
  }

  const hasPurchased = new BigNumber(paymentReceivedInWei ?? 0).isGreaterThan(0)
  const hasWithdrawn = userData?.hasWithdrawn ?? false

  let closedPeriodText = null
  if (isPurchaseable && !hasPurchased) {
    if (parseInt(estimatedAllocation) > 0) {
      closedPeriodText = (
        <IFTypography variant="caption" ifcolor="text" fontWeight="bold" textAlign="left">
          {t('Incompleted Purchase')}
        </IFTypography>
      )
    }
  } else {
    closedPeriodText = (
      <Box marginBottom={isMobile ? '0px' : '25px'} width="100%" justifyContent="space-between">
        <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
          {t(
            hasWithdrawn
              ? 'Claimed'
              : isAirdropped
              ? 'Airdropped'
              : hasPurchased
              ? 'Total Purchased'
              : 'Total Allocation',
          )}
        </IFTypography>
        <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
          {formatAmount(
            hasWithdrawn
              ? purchasedAmount
              : isAirdropped
              ? airdroppedAmount
              : hasPurchased
              ? purchasedAmount
              : estimatedAllocation,
            idoTokenSymbol || saleTokenDefaultName,
          )}
        </IFTypography>
      </Box>
    )
  }

  return (
    <>
      <Box>
        <IFTypography variant="button" ifcolor="textDisabled" fontWeight="bold" fontFamily="Roboto">
          ● {t('Closed')}
        </IFTypography>
      </Box>
      {closedPeriodText}
    </>
  )
}

const Header: React.FC<HeaderProps> = ({ sale, isMobile = false }) => {
  const header = useMemo(() => {
    const now = new Date()

    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      const {
        subscribePeriod: { startTime, endTime },
      } = sale as SubscribeableIDO

      if (now < new Date(startTime)) {
        return <SubscriptionNotStart sale={sale as SubscribeableIDO} />
      }
      if (now >= new Date(startTime) && now < new Date(endTime)) {
        return <SubscriptionStartNotEnd sale={sale as SubscribeableIDO} />
      }
    }
    if (isPurchaseableIDO(sale as PurchasableIDO)) {
      const {
        purchasePeriod: { endTime },
      } = sale as PurchasableIDO
      if (now < new Date(endTime)) {
        return <PurchaseNotEnd sale={sale as PurchasableIDO} />
      }
    }

    return <Closed sale={sale} isMobile={isMobile} />
  }, [sale])

  return <Container isMobile={isMobile}>{header}</Container>
}

export default Header
