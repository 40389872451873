import BigNumber from 'bignumber.js'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import { useTranslation } from 'contexts/Localization'
import { ApprovalState } from 'hooks/useApproveCallback'
import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button, CheckmarkIcon, Flex, PolygonRightIcon, useMatchBreakpoints } from 'uikit'
import { convertFromWei, convertToWei } from 'utils/formatBalance'

const RoundContainer = styled(Flex)<{ disabled?: boolean; filled?: boolean }>`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${({ filled }) => (filled ? `background: white;` : 'border: 2px solid white;')}
  ${({ disabled, theme }) =>
    disabled &&
    `
      border: none; 
      background: ${theme.colorsV2?.main}; 
    `}
`

interface ActionBtnWithApproveFlowProps {
  approvalState: ApprovalState
  approveHandler: (any) => void
  userInput: string
  balance: BigNumber
  actionHandler: () => void
  inProgress?: boolean
  textWithoutApproval: string
  textWithApproval: string
  textInsufficient?: string
  textInvalid?: string
  isValid?: boolean
  isMobileOverride?: boolean
  decimals?: number
}

const ActionBtnWithApproveFlow: React.FC<ActionBtnWithApproveFlowProps> = ({
  approvalState,
  approveHandler,
  userInput,
  balance,
  actionHandler,
  inProgress = false,
  textWithoutApproval,
  textWithApproval,
  textInsufficient,
  textInvalid,
  isValid = false,
  isMobileOverride = false,
  decimals = 18,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const theme = useTheme()
  const { t } = useTranslation()

  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const showApproveFlow = useMemo(() => {
    return approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING
  }, [approvalState])

  const inputValBigNumber = new BigNumber(userInput)
  const insufficient = balance.isLessThan(inputValBigNumber)
  const invalidVal = inputValBigNumber.isNaN() || inputValBigNumber.isEqualTo(new BigNumber(0)) || !isValid
  const disbaled = insufficient || invalidVal || inProgress

  const InvalidStateBtn = useMemo(() => {
    if (invalidVal || insufficient) {
      return (
        <Button disabled width="100%">
          {(invalidVal ? textInvalid : textInsufficient) ?? textWithoutApproval}
        </Button>
      )
    }
    return null
  }, [invalidVal, insufficient, textInsufficient, textInvalid, textWithoutApproval])

  const approvalFlowBtn = useMemo(() => {
    if (!showApproveFlow) return null

    const amount = convertToWei(new BigNumber(userInput), decimals).toFixed(0).toString()

    return (
      <Flex flexDirection={isMobile || isMobileOverride ? 'column' : 'row'}>
        <Button
          variant={approvalState === ApprovalState.APPROVED ? 'success' : 'primary'}
          onClick={() => approveHandler(amount)}
          isLoading={inProgress}
          disabled={approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted}
          width="100%"
          startIcon={
            inProgress ? (
              <WhiteCircleLoader size="24px" />
            ) : (
              <RoundContainer
                disabled={approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                filled={approvalState === ApprovalState.APPROVED}
              >
                {approvalState === ApprovalState.APPROVED ? (
                  <CheckmarkIcon width="14px" color={theme.colorsV2?.textDisabled} />
                ) : (
                  '1'
                )}
              </RoundContainer>
            )
          }
        >
          {inProgress
            ? t('Approving')
            : approvalSubmitted && approvalState === ApprovalState.APPROVED
            ? t('Approved')
            : t('Approve')}
        </Button>
        <Flex
          justifyContent="center"
          alignItems="center"
          px="8px"
          py="3px"
          style={{ transform: (isMobile || isMobileOverride) && 'rotate(90deg)' }}
        >
          <PolygonRightIcon color={theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled} />
        </Flex>
        <Button
          startIcon={
            <RoundContainer disabled={invalidVal || approvalState !== ApprovalState.APPROVED}>2</RoundContainer>
          }
          variant="primary"
          onClick={actionHandler}
          width="100%"
          disabled={invalidVal || approvalState !== ApprovalState.APPROVED}
        >
          {textWithApproval}
        </Button>
      </Flex>
    )
  }, [
    showApproveFlow,
    actionHandler,
    textWithApproval,
    isMobileOverride,
    isMobile,
    t,
    theme,
    approvalState,
    approveHandler,
    approvalSubmitted,
    invalidVal,
  ])

  const normalBtn = useMemo(() => {
    return (
      <Button disabled={disbaled || approvalState !== ApprovalState.APPROVED} width="100%" onClick={actionHandler}>
        {textWithoutApproval}
      </Button>
    )
  }, [disbaled, actionHandler, textWithoutApproval, approvalState])

  if (InvalidStateBtn) return InvalidStateBtn
  if (approvalFlowBtn) return approvalFlowBtn
  return normalBtn
}

export default ActionBtnWithApproveFlow
