import React, { useContext } from 'react'
import '@rainbow-me/rainbowkit/styles.css'
import '@zkmelabs/widget/dist/style.css'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { RainbowKitProvider, darkTheme, lightTheme, type Chain, type Theme } from '@rainbow-me/rainbowkit'

export interface MyWalletOptions {
  projectId: string
  chains: Chain[]
}

import { ThemeProvider } from '@mui/material/styles'
import merge from 'lodash/merge'

import { publicProvider } from 'wagmi/providers/public'
import ListsUpdater from 'state/lists/updater'
import MulticallUpdater from 'state/multicall/updater'
import TransactionUpdater from 'state/transactions/updater'
import useTheme from 'hooks/useTheme'
import getConnectors from 'constants/whitelabel/wallets'
import { CompanyContext } from 'contexts/CompanyContext'

import App from './App'
import { getCustomTheme } from './theme'

const { connectors, chains } = getConnectors()

const { publicClient, webSocketPublicClient } = configureChains(chains, [publicProvider()])

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
})

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}
const WagmiApp: React.FC = () => {
  const { isDark, theme } = useTheme()
  const { brand, isJustSale } = useContext(CompanyContext)

  const customTheme = getCustomTheme(brand)

  const myTheme = merge(isDark ? darkTheme() : lightTheme(), {
    colors: {
      connectButtonBackground: theme.common.connectButtonBackground,
      connectButtonInnerBackground: theme.common.connectButtonInnerBackground,
      profileAction: theme.common.profileAction,
      profileForeground: theme.common.profileForeground,
      closeButtonBackground: theme.common.closeButtonBackground,
      modalBackground: theme.common.modalBackground,
    },
  } as Theme)

  return (
    <WagmiConfig config={config}>
      <ThemeProvider theme={customTheme}>
        <RainbowKitProvider modalSize="compact" chains={chains} theme={myTheme}>
          <Updaters />
          <App />
        </RainbowKitProvider>
      </ThemeProvider>
    </WagmiConfig>
  )
}

export default React.memo(WagmiApp)
