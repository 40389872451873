/* eslint-disable import/no-cycle */

import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'
import { getBep20Contract } from '../utils/contractHelpers'

export const useGetBalance = (tokenAddress: string, userAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const web3 = useWeb3()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      if (!tokenAddress) {
        console.error(`Fetching an undefined address`)
        return
      }
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf(userAddress).call()
      setBalance(new BigNumber(res))
    }

    if (web3 && userAddress) {
      fetchBalance()
    }
  }, [userAddress, tokenAddress, web3, fastRefresh])

  return balance
}

export const useBnbPrice = (): BigNumber => {
  return new BigNumber(0)
}

export default useGetBalance
