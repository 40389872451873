import { useAccount } from 'wagmi'
import { useCallback, useState } from 'react'
import { ComplianceStatus, KYCSource, KYCStatus } from 'state/types'
import { getWithExpiry, setWithExpiry } from 'utils/localStorageHelper'

import useSWR from 'swr'

const USER_STORAGE_KEY = 'USER_STATUS_DATA'
const URL = process.env.REACT_APP_BACKEND_URL

type KYCResponse = {
  status: KYCStatus
  complianceStatus: ComplianceStatus
  complianceCountry?: string
  kycSource?: KYCSource
  info?: string
  refreshTrigger: () => void
}

export const useKycStatus = (): KYCResponse => {
  const { address: account } = useAccount()
  const [refreshCache, setRefreshCache] = useState(0)

  const refreshTrigger = useCallback(() => {
    setRefreshCache((prev) => prev + 1)
  }, [])

  const cacheKey = account?.length > 0 ? `${USER_STORAGE_KEY}_${account}` : USER_STORAGE_KEY

  const getStatus = useCallback(async () => {
    const cachedData = getWithExpiry(cacheKey, 2)

    if (cachedData) {
      return cachedData
    }

    if (!account) {
      console.info('Wallet not connected')
      return false
    }

    // Migrate to new backend API
    try {
      // New backend API
      const ENDPOINT = `${URL}/api/backend-service/address/${account}/status`
      const response = await (
        await fetch(ENDPOINT, {
          method: 'GET',
          headers: [['Cache-Control', 'no-cache']],
        })
      ).json()

      return response
    } catch (e) {
      console.info('KYC status error', e)
      return false
    }
  }, [account, refreshCache])

  const { data = {} } = useSWR([`useKycStatus|${account}`], getStatus, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  if (data) {
    data.complianceStatus = data.compliance_status || ComplianceStatus.EMPTY
    data.kycSource = data.kyc_source || ''
    data.complianceCountry = data.compliance_country ? data.compliance_country : data.country || ''
    data.refreshTrigger = refreshTrigger

    // Change here for quick status change
    // data.kycSource = 'AUTHENTO'
    // data.status = KYCStatus.PENDING_POA

    // If verified, it is rare for this user to be unverified, let's cache it for 24 hours
    if (data.status === KYCStatus.VERIFIED) {
      setWithExpiry(cacheKey, data, 24 * 60 * 60 * 1000, 3)
    }

    return data
  }
  return {
    refreshTrigger,
    status: KYCStatus.NOT_FOUND,
    complianceStatus: ComplianceStatus.EMPTY,
    complianceCountry: '',
  }
}

export default useKycStatus
