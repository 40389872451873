import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import { StepRequirementEnum } from 'state/types'
import { ClaimableIDO, PurchasableIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { useSaleUserData } from 'state/idos/hooks'
import { convertFromWei } from 'utils/formatBalance'
import { SoldOutDark, SoldOutLight } from 'componentsV2/Icons/Sale/SoldOut'

import SaleDetailCard from '../SaleDetailCard'

const PurchasedAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
`

const SoldOutCard: React.FC<{ sale: PurchasableIDO; projectImage: string }> = ({ sale, projectImage }) => {
  const userData = useSaleUserData(sale.id)
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    purchasePeriod: { salePrice, endTime },
    chainId,
    paymentToken,
    saleToken,
    stepRequirement,
  } = sale

  const { claimPeriod } = sale as unknown as ClaimableIDO
  const isClaimable = !!claimPeriod?.startTime

  const countdownDate = isClaimable ? claimPeriod.startTime : endTime

  const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) ?? new BigNumber(0)

  const paymentReceivedInPaymentToken = convertFromWei(paymentReceivedInWei).div(
    new BigNumber(10).pow((paymentToken.decimals || 18) - (saleToken.decimals || 18)),
  )

  const purchasedAmount = paymentReceivedInPaymentToken.dividedBy(salePrice)
  const isPurchased = paymentReceivedInPaymentToken.isGreaterThan(0)

  const countdownInfo = useMemo(() => {
    const claimTimestamp = new Date(countdownDate).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((claimTimestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t(isClaimable ? 'Claims in' : 'Purchase closes in'),
    }
  }, [t, countdownDate])

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <SoldOutDark /> : <SoldOutLight />,
    }
  }, [theme])

  const title = t(isPurchased ? 'Thank you for your participation.' : 'The sale has ended')

  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t(
        isPurchased
          ? 'Tokens has been sold out. Claim your tokens when the claiming period starts.'
          : 'Token has been sold out',
      ),
    }
  }, [t])

  const child = useMemo(() => {
    const amount = new BigNumber(purchasedAmount).decimalPlaces(2).toNumber().toLocaleString()
    return (
      <PurchasedAmountWrapper>
        <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
          {isPurchased ? t('Your purchased tokens') : t('Your Total Share')}
        </IFTypography>
        <Box display="flex" alignContent="center">
          <Box marginRight="10px">
            <img src={saleToken.tokenImage || projectImage} alt="token_icon" width="36px" />
          </Box>
          <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
            {amount}
          </IFTypography>
        </Box>
      </PurchasedAmountWrapper>
    )
  }, [saleToken, projectImage, purchasedAmount, t, isPurchased])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={chainId}
      countdownInfo={countdownInfo}
      imageInfo={imageInfo}
      title={title}
      subtitleInfo={subtitleInfo}
    >
      {child}
    </SaleDetailCard>
  )
}

export default SoldOutCard
