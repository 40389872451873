import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import Logo from 'componentsV2/Logo/Logo'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import { isSubscribeableIDO, isDroppedIDO, isPurchaseableIDO, isFixedAllocationIDO } from 'state/idos/saleUtil'
import { SubscribeableIDO, IDO, DroppedIDO, PurchasableIDO, FixedAllocationIDO } from 'state/v2_types'
import styled from 'styled-components'
import { getAllocatedAmount } from './helper'

interface Props {
  sale: IDO
}

interface BottomInfoProps {
  sale: IDO
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: space-between;
    &:not(:first-child):not(:last-child) {
      margin-top: 10px;
    }
  }
`

const PercentagebarContainer = styled.div`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
  border-radius: 41px;
  height: 8px;
  display: flex;

  & > div {
    background: linear-gradient(249.75deg, #ff8686 0.91%, #ff0e73 91.5%);
    border-radius: 41px;
    height: 8px;
  }
`

const Percentagebar: React.FC<Props> = ({ sale }) => {
  const { t } = useTranslation()
  const { symbol: saleTokenSymbol } = sale.token
  const { saleAmount } = sale
  const saleTokenTotalSupply = new BigNumber(saleAmount)
  const allocated = getAllocatedAmount(sale)
  const isAirdrop = isDroppedIDO(sale as DroppedIDO)
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)

  if (allocated == null || allocated.isNaN() || !isPurchaseable) {
    return null
  }
  const percentage = allocated.div(saleTokenTotalSupply).multipliedBy(100).decimalPlaces(2).toString()

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <PercentagebarContainer>
        <Box width={`${percentage}%`} />
      </PercentagebarContainer>
      <Box display="flex" justifyContent="space-between" marginTop="5px">
        <IFTypography variant="caption" ifcolor="textSecondary">
          {allocated.toNumber().toLocaleString()}/{saleTokenTotalSupply.toNumber().toLocaleString()} {saleTokenSymbol}{' '}
          {t(isAirdrop && !isPurchaseable ? 'Allocated' : 'Sold')}
        </IFTypography>
        <IFTypography variant="caption" ifcolor="text">
          {percentage}%
        </IFTypography>
      </Box>
    </Box>
  )
}

const BottomInfo: React.FC<BottomInfoProps> = ({ sale }) => {
  const { t } = useTranslation()
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)
  const isSubscribeable = isSubscribeableIDO(sale as SubscribeableIDO)
  const isAirdropped = isDroppedIDO(sale as DroppedIDO)
  const isFixedAllocation = isFixedAllocationIDO(sale as FixedAllocationIDO)
  const infoItems = useMemo(() => {
    const ret = []

    const paymentToken = !isPurchaseable ? null : (sale as PurchasableIDO).paymentToken
    const stakingToken = !isSubscribeable ? null : (sale as SubscribeableIDO).stakingToken
    const idoToken = sale.token

    if (stakingToken?.symbol) {
      ret.push(
        <div key="stakingRequest">
          <IFTypography variant="caption" ifcolor="textSecondary">
            {t('Staking Request')}
          </IFTypography>
          <Box display="flex" alignItems="center">
            <Logo srcs={[`/images/tokens/${stakingToken?.symbol}.png`]} width={18} height={18} alt="token" />
            <Box marginLeft="5px" display="inline-block">
              <IFTypography variant="caption" ifcolor="text">
                {stakingToken?.symbol ?? '-'}
              </IFTypography>
            </Box>
          </Box>
        </div>,
      )
    }

    if (isFixedAllocation && isPurchaseable) {
      const saleTokenAllocation = (sale as FixedAllocationIDO).saleTokenAllocationOverride
      const purchaseCap = new BigNumber(saleTokenAllocation).toNumber().toLocaleString()
      ret.push(
        <div>
          <IFTypography variant="caption" ifcolor="textSecondary">
            {t('Purchase Cap')}
          </IFTypography>
          <IFTypography variant="caption" ifcolor="text">
            {saleTokenAllocation != null ? `${purchaseCap} ${idoToken.symbol}` : ''}
          </IFTypography>
        </div>,
      )
    } else if ((sale as SubscribeableIDO).subscribePeriod) {
      const {
        subscribePeriod: { isLimited, maxTotalStake },
      } = sale as SubscribeableIDO
      const stakingCap =
        isLimited && maxTotalStake
          ? `${new BigNumber(maxTotalStake).decimalPlaces(2).toNumber().toLocaleString()} ${stakingToken.symbol}`
          : '-'
      ret.push(
        <div key="stakingCap">
          <IFTypography variant="caption" ifcolor="textSecondary">
            {t('Staking Cap')}
          </IFTypography>
          <IFTypography variant="caption" ifcolor="text">
            {stakingCap}
          </IFTypography>
        </div>,
      )
    }

    ret.push(
      <div key="tokenForStake">
        <IFTypography variant="caption" ifcolor="textSecondary">
          {t(`Token For ${isAirdropped ? 'Drop' : 'Sale'}`)}
        </IFTypography>
        <IFTypography variant="caption" ifcolor="text">
          {new BigNumber(sale.saleAmount).decimalPlaces(2).toNumber().toLocaleString()} {idoToken.symbol}
        </IFTypography>
      </div>,
    )

    if (isPurchaseable) {
      ret.push(
        <div key="payIn">
          <IFTypography variant="caption" ifcolor="textSecondary">
            {t('Pay in')}
          </IFTypography>
          <Box display="flex" alignItems="center">
            <Logo srcs={[`/images/tokens/${paymentToken.symbol}.png`]} width={18} height={18} alt="token" />
            <Box marginLeft="5px" display="inline-block">
              <IFTypography variant="caption" ifcolor="text">
                {paymentToken.symbol}
              </IFTypography>
            </Box>
          </Box>
        </div>,
      )
    }
    return ret
  }, [sale, t, isPurchaseable, isSubscribeable, isAirdropped, isFixedAllocation])

  return (
    <Container>
      {infoItems.map((e) => e)}
      <Box flexGrow={1} />
      {/* {isSubscribeable && (
        <Box marginTop="30px">
          <Percentagebar sale={sale} />
        </Box>
      )} */}
    </Container>
  )
}

export default BottomInfo
