import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Input as NumericalInput } from 'componentsV2/CurrencyInputPanel/NumericalInput'

import Logo from 'componentsV2/Logo/Logo'
import IFTypography from '../IFTypography/IFTypography'
import { Token } from 'config/constants/types'

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colorsV2?.light};
  box-sizing: border-box;
  border-radius: 20px;
  & > div:first-child {
    background: ${({ theme }) => theme.colorsV2?.dark2};
    color: ${({ theme }) => theme.colorsV2?.text};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    padding: 14px 20px 8px;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.mediaQueries?.md} {
      padding: 17px 20px 8px;
    }
  }
  & > div:nth-child(2) {
    padding: 15px 20px;
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2.dark : theme.colorsV2?.main)};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    text-transform: unset;
  }
`

const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  & img,
  svg {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    margin-right: 15px;
    border: 2px solid ${({ theme }) => theme.colorsV2?.dark2};
    border-radius: 50%;
  }
`

const PaymentToken = styled.img`
  width: 16px !important;
  height: 16px !important;
`

const Max = styled.div<{ show: boolean }>`
  border: 1px solid ${({ theme }) => theme.colorsV2?.textThirdly};
  color: ${({ theme }) => theme.colorsV2?.textThirdly};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 3px 10px;
  width: 50px;
  cursor: pointer;
  display: ${({ show }) => (show ? 'block' : 'none')};
`

const CustomButton = styled.div<{ active: boolean }>`
  border: 1px solid ${({ theme, active }) => (active ? theme.colorsV2?.textThirdly : theme.colorsV2?.textDisabled)};
  color: ${({ theme, active }) => (active ? theme.colorsV2?.textThirdly : theme.colorsV2?.textDisabled)};
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${({ theme, active }) => (active ? theme.colorsV2?.textThirdly : theme.colorsV2?.textDisabled)};
  }
`

const Input = styled(NumericalInput)<{ isNFTSale?: boolean }>`
  color: ${({ theme }) => theme.colorsV2?.text};
  text-align: ${({ isNFTSale }) => (isNFTSale ? 'center' : 'right')};
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  margin-left: ${({ isNFTSale }) => (isNFTSale ? '0' : '70px')};

  ::placeholder {
    color: ${({ theme }) => theme.colorsV2?.textDisabled};
  }
`

const Balance = styled.span`
  cursor: pointer;
`

interface InputAreaProps {
  balance: BigNumber | null
  iconSrc: string
  symbol: string
  inputVal: string
  onInputChange: (val: string) => void
  maxAmountOnSelect?: BigNumber
  className?: string
  isLP?: boolean
  icon1Src?: string
  icon2Src?: string
  isNFTSale?: boolean
  salePrice?: number
  paymentToken?: Token
}

const InputArea: React.FC<InputAreaProps> = ({
  className,
  balance,
  iconSrc,
  symbol,
  inputVal,
  maxAmountOnSelect,
  onInputChange,
  isLP,
  icon1Src,
  icon2Src,
  isNFTSale,
  salePrice,
  paymentToken,
}) => {
  const { t } = useTranslation()
  const balanceToDisplay = useMemo(() => {
    return (balance ?? new BigNumber(0)).decimalPlaces(2).toNumber().toLocaleString()
  }, [balance])
  const showMaxButton = useMemo(() => {
    return balance.toString() !== inputVal
  }, [balance, inputVal])
  const inputChangeHandler = useCallback(
    (e: string) => {
      onInputChange(e)
    },
    [onInputChange],
  )
  const setInputAsMaxAmount = useCallback(() => {
    onInputChange((maxAmountOnSelect ?? balance ?? new BigNumber(0)).toString())
  }, [onInputChange, balance, maxAmountOnSelect])

  const substract = useCallback(() => {
    if (new BigNumber(inputVal).isEqualTo(0)) {
      return
    }
    onInputChange(new BigNumber(inputVal || 0).minus(1).toString())
  }, [onInputChange, inputVal])

  const add = useCallback(() => {
    if (new BigNumber(inputVal).isGreaterThanOrEqualTo(maxAmountOnSelect)) {
      return
    }
    onInputChange(new BigNumber(inputVal || 0).plus(1).toString())
  }, [onInputChange, inputVal])

  return (
    <Container className={className}>
      {isNFTSale ? (
        <>
          <Box>
            <TokenInfo>
              {isLP ? (
                <>
                  <Logo srcs={[icon1Src]} alt="icon" />
                  <Logo srcs={[icon2Src]} alt="icon" style={{ marginLeft: -24 }} />
                </>
              ) : (
                <Logo srcs={[iconSrc]} alt="icon" />
              )}
              <Box>
                <IFTypography variant="body2" fontFamily="Roboto" fontWeight="bold" lineHeight="20px" ifcolor="text">
                  {symbol}
                </IFTypography>
                <Box display="flex" alignItems="center" columnGap={1}>
                  <IFTypography variant="caption" fontFamily="Roboto" lineHeight="20px" ifcolor="textSecondary">
                    1 NFT = {salePrice} {paymentToken.symbol}
                  </IFTypography>
                  <img style={{ width: '16px', height: '16px' }} src={paymentToken.tokenImage} />
                </Box>
              </Box>
            </TokenInfo>
          </Box>
          <Box>
            <CustomButton onClick={substract} active={parseInt(inputVal || '0') > 0}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <g clipPath="url(#clip0_34472_42225)">
                  <path d="M13 13.5H18C18.5523 13.5 19 13.0523 19 12.5C19 11.9477 18.5523 11.5 18 11.5H13H11H6C5.44772 11.5 5 11.9477 5 12.5C5 13.0523 5.44772 13.5 6 13.5H11H13Z" />
                </g>
                <defs>
                  <clipPath id="clip0_34472_42225">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>
            </CustomButton>
            <Input isNFTSale wholeNumber placeholder="0" value={inputVal} onUserInput={inputChangeHandler} />
            <CustomButton onClick={add} active={new BigNumber(parseInt(inputVal || '0')).isLessThan(maxAmountOnSelect)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <g clipPath="url(#clip0_34472_42233)">
                  <path d="M19 12.5C19 13.0523 18.5523 13.5 18 13.5H13V18.5C13 19.0523 12.5523 19.5 12 19.5C11.4477 19.5 11 19.0523 11 18.5V13.5H6C5.44772 13.5 5 13.0523 5 12.5C5 11.9477 5.44772 11.5 6 11.5H11V6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5V11.5H18C18.5523 11.5 19 11.9477 19 12.5Z" />
                </g>
                <defs>
                  <clipPath id="clip0_34472_42233">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>
            </CustomButton>
            <Box marginLeft={3}>
              <Max onClick={setInputAsMaxAmount} show>
                <IFTypography variant="button" lineHeight="20px" ifcolor="textThirdly" fontFamily="Roboto">
                  {t('Max')}
                </IFTypography>
              </Max>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <TokenInfo>
              {isLP ? (
                <>
                  <Logo srcs={[icon1Src]} alt="icon" />
                  <Logo srcs={[icon2Src]} alt="icon" style={{ marginLeft: -24 }} />
                </>
              ) : (
                <Logo srcs={[iconSrc]} alt="icon" />
              )}
              <IFTypography variant="body2" fontFamily="Roboto" fontWeight="bold" lineHeight="20px" ifcolor="text">
                {symbol}
              </IFTypography>
            </TokenInfo>
            <Balance onClick={setInputAsMaxAmount}>
              <IFTypography fontFamily="Roboto" lineHeight="18px" variant="overline" ifcolor="text">{`${t(
                'Balance',
              )}: ${balanceToDisplay}`}</IFTypography>
            </Balance>
          </Box>
          <Box>
            <Max onClick={setInputAsMaxAmount} show={showMaxButton}>
              <IFTypography variant="button" lineHeight="20px" ifcolor="textThirdly" fontFamily="Roboto">
                {t('Max')}
              </IFTypography>
            </Max>
            <Input placeholder="0" value={inputVal} onUserInput={inputChangeHandler} />
          </Box>
        </>
      )}
    </Container>
  )
}

export default InputArea
