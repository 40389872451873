import { fetchProjectsConfig, fetchBannersConfig } from 'state/idos/fetchConfigs'
import { Banner, Project, PaidStakeSaleConfig, PrivateIDOConfig } from 'state/v2_types'
import { isPurchaseableIDO } from 'state/idos/saleUtil'

export const getProjects = async (account): Promise<Project[]> => {
  const projects = await fetchProjectsConfig(account, false)
  return projects
}

export const getProjectsWithAllocations = async (account): Promise<Project[]> => {
  const projects = await fetchProjectsConfig(account, true)
  return projects
}

export const getBanners = async (): Promise<Banner[]> => {
  const banners = await fetchBannersConfig()
  return banners
}

export const filterNullSaleAddressSale = (
  idos: (PaidStakeSaleConfig | PrivateIDOConfig)[],
): (PaidStakeSaleConfig | PrivateIDOConfig)[] => {
  return idos.filter((val) => val.saleAddress)
}

export const filterFreeSale = (
  idos: (PaidStakeSaleConfig | PrivateIDOConfig)[],
): (PaidStakeSaleConfig | PrivateIDOConfig)[] => {
  return idos.filter((val) => isPurchaseableIDO(val))
}

export default getProjects
