/* eslint-disable no-case-declarations */
/* eslint-disable dot-notation */
import { createSlice } from '@reduxjs/toolkit'
import { getProjects, getProjectsWithAllocations } from 'utils/idosHelpers'
import BigNumber from 'bignumber.js'
import { IdosState } from 'state/types'
import { FixedAllocationIDO } from 'state/v2_types'
import { convertFromWei } from 'utils/formatBalance'

import {
  fetchSalePaymentUserAllowances,
  fetchSaleStakingUserAllowances,
  fetchSaleUserStakeWeights,
  fetchSalesUserHasWithdrawn,
  fetchSalesUserPaymentReceived,
  fetchSalesUserPaymentTokenBalances,
  fetchSaleUserStakingTokenBalances,
  fetchSaleUserStakeAmounts,
  fetchUserFinalTokenAllocations,
  fetchSaleUserStakeWeightsV2,
  fetchSaleTotalStakeWeights,
  fetchUserCurrentClaimableToken,
  fetchUserOptIn,
  fetchUserStakedOnCurrentChain,
  // fetchSalesTotalPaymentReceived,
  // fetchSalesMaxTotalPurchasable,
  // fetchSalesPublicAllocation,
  // fetchSalesPurchaserCount,
} from './fetchSalesUser'

import { fetchIdos } from './fetchIdos'
import { addDetail } from './utils'
import { isFixedAllocationIDO } from './saleUtil'
import { fetchIsWhitelist } from './fetchConfigs'

const initialState: IdosState = {
  projects: [],
  projectsWithAllocations: [],
  saleUserDataMap: {},
  initialised: false,
  projectsInitialized: false,
  projectsSynced: false,
  syncedOnchain: false,

  isSaleUserDataLoading: false,
  isSaleUserDataFetched: false,
}

export const idosSlice = createSlice({
  name: 'Idos',
  initialState,
  reducers: {
    initProjects: (state, action) => {
      state.projects = action.payload
      state.projectsInitialized = true
    },
    initProjectsWithAllocations: (state, action) => {
      state.projectsWithAllocations = action.payload
    },
    syncProjects: (state, action) => {
      state.projects = action.payload
      state.projectsSynced = true
    },
    setIsSaleUserDataLoading: (state, action) => {
      state.isSaleUserDataLoading = action.payload
    },
    setIsSaleUserDataFetched: (state, action) => {
      state.isSaleUserDataFetched = action.payload
    },
    syncSaleUserDataMap: (state, action) => {
      state.saleUserDataMap = action.payload
    },
    setProjectInitialized: (state, action) => {
      state.projectsInitialized = action.payload
    },
  },
})

export const {
  syncProjects,
  initProjects,
  initProjectsWithAllocations,
  setIsSaleUserDataLoading,
  setIsSaleUserDataFetched,
  syncSaleUserDataMap,
  setProjectInitialized,
} = idosSlice.actions

export const fetchInitProjects = (account) => async (dispatch) => {
  dispatch(setProjectInitialized(false))
  const projects = await getProjects(account)
  const detailedProjects = addDetail(projects)
  dispatch(initProjects(detailedProjects))
}

export const fetchInitProjectsWithAllocations = (account) => async (dispatch) => {
  dispatch(setProjectInitialized(false))
  const projects = await getProjectsWithAllocations(account)
  const detailedProjects = addDetail(projects)
  dispatch(initProjectsWithAllocations(detailedProjects))
}

export const fetchProjectsDataAsync = (account) => async (dispatch, getState) => {
  const { idos } = getState()
  const { projects } = idos

  const allPrivateSaleIds = projects.reduce((final, project, proIndex) => {
    const inner = project.sales.reduce((accumulator, currentObject, index) => {
      if (currentObject.isPrivate) {
        if (accumulator?.length === 0) {
          return currentObject.saleId.toString()
        }
        return accumulator + ',' + currentObject.saleId.toString()
      }
      return accumulator
    }, '')

    if (final.length === 0) {
      return inner
    }
    return final + ',' + inner
  }, '')

  const whitelistedIds = await fetchIsWhitelist(account, allPrivateSaleIds)
  const saleIdsBoolMap = whitelistedIds.reduce((accumulator, currentValue) => {
    accumulator[currentValue] = true
    return accumulator
  }, {})

  const filteredProjects = projects.map((p) => {
    return {
      ...p,
      sales: p.sales.filter((s) => {
        return (s.isPrivate && saleIdsBoolMap[s.id]) || !s.isPrivate
      }),
    }
  })

  const syncedProjects = await fetchIdos(filteredProjects)
  dispatch(syncProjects(syncedProjects))
}

// TODO: Create one for Projects > Sales list
export const fetchIdoUserDataAsync = (account) => async (dispatch) => {
  dispatch(fetchSalesUserDataAsync(account))
}

export const fetchSalesUserDataAsync = (account) => async (dispatch, getState) => {
  dispatch(setIsSaleUserDataLoading(true))
  const { idos, blocks } = getState()

  const { projects } = idos

  // Flatten the sales
  const sales = projects.flatMap((project) => project.sales).filter((sale) => !!sale)
  const functions = [
    { key: 'userHasWithdrawn', fn: fetchSalesUserHasWithdrawn(sales, account) },
    { key: 'usePaymentReceived', fn: fetchSalesUserPaymentReceived(sales, account) },
    { key: 'usePaymentTokenBalances', fn: fetchSalesUserPaymentTokenBalances(sales, account) },
    // TODO 6: Remove non purchase related call
    { key: 'userStakingTokenBalances', fn: fetchSaleUserStakingTokenBalances(sales, account) },
    { key: 'userStakingTokenAllowances', fn: fetchSaleStakingUserAllowances(sales, account) },
    { key: 'userPaymentTokenAllowances', fn: fetchSalePaymentUserAllowances(sales, account) },
    { key: 'totalStakeWeights', fn: fetchSaleTotalStakeWeights(sales, blocks) },
    { key: 'userStakeWeights', fn: fetchSaleUserStakeWeights(sales, account, blocks) },
    { key: 'userStakeWeightsV2', fn: fetchSaleUserStakeWeightsV2(sales, account) },
    { key: 'userStakeAmounts', fn: fetchSaleUserStakeAmounts(sales, account) },
    { key: 'userFinalTokenAllocations', fn: fetchUserFinalTokenAllocations(sales, account) },
    { key: 'userCurrentClaimableTokens', fn: fetchUserCurrentClaimableToken(sales, account) },
    { key: 'userHasOptedIn', fn: fetchUserOptIn(sales, account) },
    { key: 'userStakedOnCurrentChain', fn: fetchUserStakedOnCurrentChain(sales, account) },

    // { key: 'totalPaymentReceived', fn: fetchSalesTotalPaymentReceived(sales, blocks) },
    // { key: 'maxTotalPurchasable', fn: fetchSalesMaxTotalPurchasable(sales, blocks) },
    // { key: 'publicAllocation', fn: fetchSalesPublicAllocation(sales, blocks) },
    // { key: 'purchaserCount', fn: fetchSalesPurchaserCount(sales, blocks) },
  ]
  const results = await Promise.allSettled(functions.map((val) => val.fn))

  const keyedResult = results.reduce((acc, result, idx) => {
    if (result.status !== 'fulfilled') {
      acc[functions[idx].key] = {}
    }

    acc[functions[idx].key] = (result as any).value
    return acc
  }, {})

  const arrayOfUserDataObjects = sales.map((sale) => {
    let userTokenAllocation = new BigNumber(0)
    const finalTokenAllocation = normalizeData(keyedResult['userFinalTokenAllocations'][sale.id], new BigNumber(0))
    const userStakeWeight: BigNumber = normalizeData(
      keyedResult['userStakeWeightsV2'][sale.id],
      normalizeData(keyedResult['userStakeWeights']?.[sale.id], new BigNumber(0)),
    )
    const totalStakeWeight: BigNumber = normalizeData(keyedResult['totalStakeWeights']?.[sale.id], new BigNumber(0))

    if (isFixedAllocationIDO(sale as FixedAllocationIDO)) {
      userTokenAllocation = new BigNumber(sale.saleTokenAllocationOverride)
    } else if (new BigNumber(finalTokenAllocation).gt(0)) {
      userTokenAllocation = convertFromWei(finalTokenAllocation, sale.token.decimals)
    } else if (userStakeWeight.gt(0) && totalStakeWeight.gt(0)) {
      userTokenAllocation = userStakeWeight.div(totalStakeWeight).times(sale.saleAmount)
    }

    return {
      id: sale.id,
      hasWithdrawn: (keyedResult['userHasWithdrawn'][sale.id] && keyedResult['userHasWithdrawn'][sale.id][0]) || false,
      paymentReceivedInWei: keyedResult['usePaymentReceived'][sale.id] || new BigNumber(0),
      paymentTokenBalanceInWei: keyedResult['usePaymentTokenBalances'][sale.id],
      // totalPaymentReceivedInWei: keyedResult['totalPaymentReceived'][sale.id],
      // maxTotalPurchasableInWei: keyedResult['maxTotalPurchasable'][sale.id],
      // publicAllocationInWei: keyedResult['publicAllocationInWei'][sale.id],

      stakingTokenBalanceInWei: keyedResult['userStakingTokenBalances'][sale.id],
      stakingTokenAllowanceInWei: keyedResult['userStakingTokenAllowances'][sale.id],
      paymentTokenAllowanceInWei: keyedResult['userPaymentTokenAllowances'][sale.id],
      currentClaimableTokenInWei: keyedResult['userCurrentClaimableTokens'][sale.id],
      userStakeWeight,
      userStakeAmount: keyedResult['userStakeAmounts'][sale.id] || new BigNumber(0).toJSON(),
      userTokenAllocation,
      userHasOptedIn: keyedResult['userHasOptedIn'][sale.id]?.[0] || false,
      userStakedOnCurrentChain: keyedResult['userStakedOnCurrentChain'][sale.id]?.[0],
    }
  })

  // Convert to map for easy fetch
  const saleUserDataMap = sales.reduce((acc, sale, idx) => {
    acc[sale.id] = arrayOfUserDataObjects[idx]
    return acc
  }, {})

  dispatch(syncSaleUserDataMap(saleUserDataMap))
  dispatch(setIsSaleUserDataLoading(false))
  dispatch(setIsSaleUserDataFetched(true))
}

export default idosSlice.reducer

const normalizeData = (data: BigNumber | string, defaultVal?: BigNumber) => {
  const val = new BigNumber(data)
  if (val.isNaN()) {
    return defaultVal ?? new BigNumber(0)
  }
  return val
}
