import BigNumber from 'bignumber.js'
import { Chain, Token } from 'config/constants/types'
import { Difficulty } from 'views/Quest/types'
import {
  StepRequirementEnum,
  PublicSaleUserDataType,
  BonusSaleUserDataType,
  StakeDropSaleUserDataType,
  BonusSaleInfoType,
  INTERVAL_UNIT,
} from './types'

export enum BannerType {
  IDO = 'ido',
  PROMO = 'promo',
}

export enum TagType {
  UPCOMING = 'upcoming',
  LIVE = 'live',
  ANNOUNCEMENT = 'announcement',
  EVENT = 'event',
}

export type SocialMediaType = {
  name?: string
  icon?: string
  url: string
}

export interface Banner {
  id: string
  type: BannerType.IDO | BannerType.PROMO
  tag: TagType
  status?: 'LIVE' | 'UPCOMING'
  isBuyback?: boolean
  isShown?: boolean
  brand?: string
  image: string
  mobileImage?: string
  networks?: string[]
  endTime?: string
  tags?: string[]
  icon?: string
  title?: string
  startTime?: string

  endTimeText?: string
  description?: string

  link?: string
  buttonText?: string
}

export interface Partner {
  id: string
  name: string
  description?: string
  link?: string
  lightImage?: string
  darkImage?: string
}
export interface Project {
  projectId?: string
  id: string
  oldId?: string
  symbol?: string
  title: string
  image: string
  bannerImage: string
  imageLive?: string
  banner?: string
  tag?: string
  sectors?: string[]
  isBuyback?: boolean
  isLive?: boolean
  shortDescription: string
  description?: string
  projectOverview: string
  coinGeckoId: string
  socialMedia: SocialMediaType[]
  partner?: Partner
  whitepaperLink?: string
  researchLink?: string
  saleDetailsLink?: string
  isPrivate?: boolean

  featureList: string
  restrictedLabel?: string
  sales: (FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig | PaidStakeSaleConfig)[]
  // null if not advised by IF (collab partner)
  collabPartner?: CollabPartner
  postSaleInfo?: PostSaleInfo

  networks?: number[]
  saleTypes?: string[]

  projectImage?: string

  // UI FIELD ONLY
  isInnovationZone?: boolean
}

export interface CollabPartner {
  partnerName: string
  partnerImage: string
}

export interface PostSaleInfo {
  postSaleText?: string
  latestUpdatesLink?: string
  appLink?: string
}

export enum IDOType {
  PUBLIC_SALE = 'public',
  PRIVATE_SALE = 'private',
  AUCTION_SALE = 'auction',
  STAKEDROP_SALE = 'stakeDrop',
  FREE_STAKE_DROP_SALE = 'freeStakeDrop',
  PAID_STAKE_DROP_SALE = 'paidStakeDrop',
  FREE_STAKE_CLAIM_SALE = 'freeStakeClaim',
  PAID_STAKE_CLAIM_SALE = 'paidStakeClaim',
  GLEAM_CAMPAIGN_SALE = 'gleamCampaign',
}

export type IDOSaleToken = {
  name: string
  symbol: string
  image: string
  address?: string
  decimals: number
  coinGeckoId?: string
}

export enum BuybackType {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
}

export type BuybackInfo = {
  type: BuybackType
  buybackPrice: number
  paymentToken: string
  buybackStartTime: string
  buybackEndTime: string
  buttonLink?: string // For Unlocked Type
  buttonText?: string // For Unlocked Type
  tradeAppName?: string
  airdropDate?: string
  moreInformatons?: string[]
  buybackActive?: boolean
}

export interface IDO {
  saleId: string
  id: string
  oldId?: string
  type: IDOType
  chainId: number
  title: string
  projectID: string
  stepRequirement: [StepRequirementEnum]
  token: IDOSaleToken
  isArchived?: boolean
  restrictedLabel?: string
  restrictedCountries?: string[]
  isActive?: boolean
  saleAmount: number
  isPrivate?: boolean
  whitelistedUrl?: string
  isBuyback?: boolean
  buybackInfo?: BuybackInfo[]
  saleChainId?: number
  saleTokenDefaultName?: string
  saleTokenChainId?: number
  startTime?: () => string
  endTime?: () => string
  // v2 api
  saleToken?: Token
  saleTitle?: string
  saleInformation?: {
    description?: string
  }
  postSaleInformation?: {
    completionMessage?: string
  }
  saleAttributes?: {
    isPromoCodeEnabled?: boolean
    percentageCashback?: number
    isNFTSale?: boolean
  }
  userAllocation?: string

  // FE Only
  isSeparateClaim?: boolean
  isTierSale?: boolean
  isGiftBox?: boolean
  isSaleTBD?: boolean
  isPromoCodeEnabled?: boolean
  isNFTSale?: boolean

  // FE status
  isLive?: boolean
  isUpcoming?: boolean
  isEnd?: boolean
  isParticipated?: boolean
}

export interface PayableIDO {
  paymentToken: Token
}

export interface SubscribeableIDO extends IDO {
  stakingToken: Token
  masterAddress: string
  subscribePeriod: SubscribePeriod
  trackId: string
}

export interface PurchasableIDO extends IDO {
  purchasePeriod: PurchasePeriod
  paymentToken: Token
  saleAddress?: string
}

export interface ClaimableIDO extends IDO {
  saleAddress?: string
  claimPeriod: ClaimPeriod
}

export interface DroppedIDO extends IDO {
  airdropInfo: AirdropInfo
}

export interface CliffVestableIDO extends IDO {
  saleAddress?: string
  cliffVestInfo: CliffVestInfo
}

export interface LinearVestableIDO extends IDO {
  saleAddress?: string
  linearVestInfo?: {
    startTime: string
    endTime: string
  }
}

export interface FixedAllocationIDO extends IDO {
  saleTokenAllocationOverride: number
}

export interface PublicIDOConfig extends IDO, SubscribeableIDO, PurchasableIDO, ClaimableIDO {
  saleAddress?: string
  userData?: PublicSaleUserDataType
}

export interface AuctionIDOConfig extends IDO, PurchasableIDO {
  tokenId: number
  marketAddress: string
  voucherAddress: string
  vestingPoolAddress: string
  highest?: number
  lowest?: number
  isDeclining?: boolean
  durationInSeconds?: number
  intervalInSeconds?: number
  intervalUnit?: INTERVAL_UNIT
  intervalValue?: number
  userData?: BonusSaleUserDataType
  saleInfo?: BonusSaleInfoType
}

export interface PrivateIDOConfig extends IDO, FixedAllocationIDO, PurchasableIDO, ClaimableIDO, CliffVestableIDO {
  saleAddress?: string
  userData?: PrivateSaleUserDataType
}

export type PrivateSaleUserDataType = {
  paymentTokenAllowanceInWei: BigNumber
  hasWithdrawn?: boolean
  paymentReceivedInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
  // TODO: Move this out when we have BonusSaleUserDataType
  solvPaymentTokenAllowance?: BigNumber
}

export type SubscribePeriod = {
  startTime?: string
  endTime: string
  isLimited?: boolean
  maxTotalStake?: BigNumber
  numTrackStakers?: number
  allocSnapshotBlock?: number
  totalStakeWeight?: string
  totalStaked?: string
}

export type PurchasePeriod = {
  startTime: string
  endTime: string
  minimumPurchase?: number
  maximumPurchase?: number
  hasWithdrawn?: boolean
  paymentReceived?: number
  salePrice?: number
  purchaserCount: number
  saleAmount?: number
  totalPaymentReceived?: number
  buybackClaimableNumber?: number
  maxTotalPurchasable?: number
  publicAllocation?: number
}

export type ClaimPeriod = {
  startTimeTBD?: boolean
  startTime: string | Date
  purchaserCount: number
}

export type AirdropDetail = {
  name?: string
  date?: string | Date
  percentage?: number
}

export type AirdropInfo = {
  isAirdropped?: boolean
  isInitialTBD?: boolean
  initialAirdrop?: string
  isFinalTBD?: boolean
  finalAirdrop?: string
  firstDayRelease?: string
  details?: AirdropDetail[]
  airdropType?: 'NFT' | 'TOKEN'
  migratedUrl?: string
}

export type CliffVestInfo = {
  vestInfoLabel?: string
  vestInfoTooltip?: string
  vestList?: {
    date: string
    title: string
  }[]
  cliffPeriod?: {
    claimTime: Date
    pct: number
  }[]
}

export interface StakeDropIDOConfig extends IDO, PurchasableIDO, SubscribeableIDO {
  airdropInfo: AirdropInfo
  userData?: StakeDropSaleUserDataType
}

// export interface FreeStakeDropConfig extends IDO, DroppedIDO, SubscribeableIDO {
//   userData?: StakeDropSaleUserDataType
// }

export interface FreeStakeDropConfig extends IDO, DroppedIDO, SubscribeableIDO {
  userData?: FreeStakeDropUserDataType
}

export interface PaidStakeDropConfig extends IDO, PurchasableIDO, DroppedIDO, SubscribeableIDO {
  userData?: PaidStakeDropUserDataType
}

export interface FreeStakeSaleConfig extends IDO, DroppedIDO, SubscribeableIDO, ClaimableIDO, CliffVestableIDO {
  userData?: FreeStakeSaleUserDataType
}

export interface PaidStakeSaleConfig
  extends IDO,
    PurchasableIDO,
    DroppedIDO,
    SubscribeableIDO,
    ClaimableIDO,
    CliffVestableIDO {
  userData?: PaidStakeSaleUserDataType
}

export interface WhitelistedUserDataType {
  isWhitelisted?: boolean
}

export interface SubscribeableUserDataType {
  stakingTokenAllowanceInWei: BigNumber
  userStakeWeight: number
}

export interface PurchasableIDOUserDataType {
  paymentTokenAllowanceInWei: BigNumber
  paymentReceivedInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
}

export interface ClaimableIDOUserDataType {
  hasWithdrawn: boolean
  currentClaimableToken?: BigNumber
}

export type FreeStakeDropUserDataType = SubscribeableUserDataType
export interface PaidStakeDropUserDataType extends SubscribeableUserDataType, PurchasableIDOUserDataType {}

export interface FreeStakeSaleUserDataType extends SubscribeableUserDataType, ClaimableIDOUserDataType {}
export interface PaidStakeSaleUserDataType
  extends SubscribeableUserDataType,
    PurchasableIDOUserDataType,
    ClaimableIDOUserDataType {}

// Farms
export type FarmConfig = {
  id: string
  masterChefContract: string
  chainId: Chain
  stakingToken: Token
  rewardToken: Token
  token0?: Token
  token1?: Token
  rewardPerSecond?: BigNumber
  endTime?: Date
  startTime?: Date
  token0Amount?: BigNumber
  token1Amount?: BigNumber
  totalSupply?: number
  lpTokenBalanceMC?: BigNumber
  liquidityValue?: number
  apr?: number
}

export type FarmUserData = {
  stakingTokenBalance: BigNumber
  stakedTokenBalance: BigNumber
  earnings: BigNumber
  isEnabled: boolean
}

export interface FarmsState {
  data: FarmConfig[]
  userData: { [id: string]: FarmUserData }
  isFetched: boolean
  isUserDataLoading: boolean
  isUserDataFetched: boolean
  isPriceDataLoading: boolean
  isPriceDataFetched: boolean
}

export interface VestingPoolInfo {
  unstaking: BigNumber
  unstakeAt: number
  reward: string | null
  skipDelayFee: number
  cancelUnstakeFee: number
  unstakingDelay: number
}
export interface VIDIAState {
  data?: VestingPoolInfo
}

enum QuizStatus {}

// Token table from DB
export type TokenV2 = {
  decimals: number
  tokenAddress: string
  tokenSymbol: string
}

// Project table from DB
export interface ProjectV2 {
  symbol?: string
  title?: string
  projectImage?: string
}

export enum RewardType {
  NoReward = 0,
  TokenClaim = 1,
  WhitelistFeedback = 2,
  Code = 3,
}

export type Quiz = {
  quizId: number
  chainId?: number
  projectId?: string
  quizImage?: string
  quizBanner?: string
  quizTitle: string
  isPrivate?: boolean
  quizDescription?: string
  quizLevel: Difficulty
  quizArticle?: string
  articleDuration?: number
  minimumPassingScore?: number
  maxRetry?: number
  quizStatus: QuizStatus
  quizRewards?: number
  quizTokenAddress?: string
  startTime: Date
  hasReward?: boolean
  endTime: Date
  questions: Question[]
  totalSubmissions?: number
  totalRewards?: number
  submissionStatus?: SubmissionStatus
  totalRewarded?: number
  requireKYC?: boolean
  token?: TokenV2
  project?: ProjectV2
  contractAddress?: string
  rewardType?: RewardType
  rewardText?: string
}

export type QuizResult = {
  quizID?: number
  submissionID: number
  submissionResult: number
  score: number
  quizRewards: number
  minimumPassingScore: number
  totalSubmissions: number
  totalRewarded: number
  maxRetry: number
  rewardType?: RewardType
  rewardText?: string
  feedback?: string
  code?: string
  refProjectId?: number
}

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type GetQuizResponse = {
  quiz: Quiz
  status: number
  totalSubmissions: number
}

export type SubmitAnswerBody = {
  answers: number[][]
  quizId: number
}

export type JoinBuybackBody = {
  saleId: number
}

export type ShareFeedbackBody = {
  feedbackText?: string
  quizId?: number
  projectId?: number
}

export type SubmitAnswerResponse = {
  isPass: boolean
  noOfRetry: number
  score: number
  totalReward: number
}

export enum QuestionType {
  SingleChoice = 0,
  MultipleChoices = 1,
}

export enum SubmissionStatus {
  OPEN = 0,
  COMPLETED = 1,
}

enum QuestionStatus {}

export type Question = {
  questionId: number
  quizId: number
  questionType: QuestionType
  questionTitle: string
  questionDescription?: string
  questionStatus?: QuestionStatus
  options: Option[]
}

export enum OptionStatus {}

export type Option = {
  optionId: number
  questionId: number
  optionTitle: string
  optionStatus?: OptionStatus
}
/* == end of quiz types == */

/* == KYC types == */
export type SubmitKYCBody = {
  countryCode: string
  source: string
}

export type SubmitKYCSourceBody = {
  source: string
}

/* == end of KYC types == */

/* == Referral types == */
export type SendReferral = {
  code: string
  address: string
  action: string
}
