import BigNumber from 'bignumber.js'
import {
  Project,
  SubscribePeriod,
  ClaimPeriod,
  PurchasePeriod,
  IDO,
  SubscribeableIDO,
  PurchasableIDO,
  ClaimableIDO,
  DroppedIDO,
  FixedAllocationIDO,
  CliffVestableIDO,
  LinearVestableIDO,
} from 'state/v2_types'
import IFAllocationMaster from 'config/abi/IFAllocationMaster.json'
import IFAllocationSale from 'config/abi/IFAllocationSale.json'

import { multicallv2 } from 'utils/multicall'
import { convertFromWei } from 'utils/formatBalance'
import { Chain } from 'config/constants/types'
import {
  isSubscribeableIDO,
  isPurchaseableIDO,
  isClaimableIDO,
  isDroppedIDO,
  isFixedAllocationIDO,
  isCliffVestableIDO,
  isLinearVestableIDO,
} from './saleUtil'
import { useLiveProjects } from './hooks'
import { useMemo } from 'react'

export const fetchMaxTotalStake = async (
  masterAddress: string,
  trackId: string,
  chainId: Chain,
): Promise<BigNumber> => {
  const afterEffectCalls = [
    {
      address: masterAddress,
      name: 'tracks',
      params: [trackId],
    },
  ]
  const [trackInformation] = await multicallv2(IFAllocationMaster.abi, afterEffectCalls, chainId)
  const maxTotalStake = convertFromWei(new BigNumber([trackInformation.maxTotalStake] as any))

  return maxTotalStake
}

export type AllocationMasterData = {
  maxTotalStake: BigNumber
  numTrackStakers: string
  totalStaked: string
}

export const fetchAllocationMasterData = async (
  masterAddress: string,
  trackId: string,
  chainId: Chain,
  decimals: number,
): Promise<AllocationMasterData> => {
  let totalStaked = new BigNumber(0)
  let afterEffectCalls = [
    {
      address: masterAddress,
      name: 'tracks',
      params: [trackId],
    },
    {
      address: masterAddress,
      name: 'numTrackStakers',
      params: [trackId],
    },
    { address: masterAddress, name: 'trackCheckpointCounts', params: [trackId] },
  ]
  const [trackInformation, numTrackStakers, checkpointsRes] = await multicallv2(
    IFAllocationMaster.abi,
    afterEffectCalls,
    chainId,
  )
  const maxTotalStake = convertFromWei(new BigNumber([trackInformation.maxTotalStake] as any), decimals)
  const checkpoints = checkpointsRes[0]
  if (checkpoints === 0) {
    return {
      maxTotalStake,
      numTrackStakers,
      totalStaked: totalStaked.toString(),
    }
  }

  afterEffectCalls = [
    {
      address: masterAddress,
      name: 'trackCheckpoints',
      params: [trackId, (checkpoints - 1).toString()],
    },
  ]
  const totalCount = await multicallv2(IFAllocationMaster.abi, afterEffectCalls, chainId)
  totalStaked = convertFromWei(new BigNumber([totalCount[0]?.totalStaked ?? 0] as any), decimals)

  return {
    maxTotalStake,
    numTrackStakers,
    totalStaked: totalStaked.toString(),
  }
}
export type AllocationSaleData = {
  subscribe: SubscribePeriod
  purchase: PurchasePeriod
  claim: ClaimPeriod
}

export const fetchSaleInfo = async (saleAddress: string, chainId: number): Promise<any> => {
  console.log('TXN fetchSaleInfo')
  const calls = [
    {
      address: saleAddress,
      name: 'purchaserCount',
    },
    {
      address: saleAddress,
      name: 'totalPaymentReceived',
    },
    { address: saleAddress, name: 'buybackClaimableNumber' },
    { address: saleAddress, name: 'maxTotalPurchasable' },
    { address: saleAddress, name: 'publicAllocation' },
  ]
  const result = await multicallv2(IFAllocationSale, calls, chainId)
  return result
}

export const fetchAllocationSaleData = async (saleAddress: string, chainId: number): Promise<any> => {
  console.log('TXN fetchAllocationSaleData')

  const calls = [
    {
      address: saleAddress,
      name: 'startTime',
    },
    // end block
    {
      address: saleAddress,
      name: 'endTime',
    },
    // minimum payment
    {
      address: saleAddress,
      name: 'minTotalPayment',
    },
    // maximum payment
    {
      address: saleAddress,
      name: 'maxTotalPayment',
    },
    // sale amount
    {
      address: saleAddress,
      name: 'saleAmount',
    },
    // sale price
    {
      address: saleAddress,
      name: 'salePrice',
    },
    {
      address: saleAddress,
      name: 'allocSnapshotBlock',
    },
    {
      address: saleAddress,
      name: 'allocSnapshotTimestamp',
    },
    {
      address: saleAddress,
      name: 'withdrawDelay',
    },
    {
      address: saleAddress,
      name: 'purchaserCount',
    },
    {
      address: saleAddress,
      name: 'saleTokenAllocationOverride',
    },
    {
      address: saleAddress,
      name: 'totalPaymentReceived',
    },
    { address: saleAddress, name: 'getCliffPeriod' },
    { address: saleAddress, name: 'buybackClaimableNumber' },
  ]
  const result = await multicallv2(IFAllocationSale, calls, chainId)
  return result
}

export const getSaleType = (sale: IDO): string => {
  if (sale == null) return ''
  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const { subscribePeriod } = sale as SubscribeableIDO
    return subscribePeriod?.isLimited && subscribePeriod?.maxTotalStake ? 'Standard' : 'Unlimited'
  }

  if (sale?.token?.symbol === 'dAethirNode') {
    return 'Staking Allocation'
  }

  if (isDroppedIDO(sale as DroppedIDO)) return 'Staking Rewards'

  return ''
}

export const addDetail = (projects: Project[]): Project[] => {
  const detailedProjects = projects.map((project) => {
    const networks = []
    const networkMap = {}

    const saleTypes = []
    const typeMap = {}
    for (let i = 0; i < project.sales?.length; i++) {
      const sale = project.sales[i]

      if (isSaleLive(sale)) {
        project.isLive = true
      }

      if (!networkMap[sale.chainId]) {
        networkMap[sale.chainId] = true
        networks.push(sale.chainId)
      }

      const saleType = getSaleType(sale)
      if (!typeMap[saleType]) {
        typeMap[saleType] = true
        if (saleType.length > 0) {
          saleTypes.push(saleType)
        }
      }
    }

    return { ...project, networks, saleTypes }
  })

  return detailedProjects
}

const filterLive = (projects: Project[]): Project[] => {
  const now = new Date()

  const filteredProjects = projects.reduce((filtered, project) => {
    let isLive = false

    for (let i = 0; i < project.sales?.length; i++) {
      const sale = project.sales[i]

      let finalStartTime = new Date()
      let finalEndTime = new Date(0)

      if (isSubscribeableIDO(sale as SubscribeableIDO)) {
        const { subscribePeriod } = sale as SubscribeableIDO
        const { startTime, endTime } = subscribePeriod
        finalStartTime = finalStartTime > new Date(startTime) ? new Date(startTime) : finalStartTime
        finalEndTime = finalEndTime > new Date(endTime) ? finalEndTime : new Date(endTime)
      }

      if (isPurchaseableIDO(sale as PurchasableIDO)) {
        const { purchasePeriod } = sale as PurchasableIDO
        const { startTime, endTime } = purchasePeriod
        finalStartTime = finalStartTime > new Date(startTime) ? new Date(startTime) : finalStartTime
        finalEndTime = finalEndTime > new Date(endTime) ? finalEndTime : new Date(endTime)
      }

      if (isClaimableIDO(sale as ClaimableIDO)) {
        const { claimPeriod } = sale as ClaimableIDO
        const { startTime } = claimPeriod

        finalEndTime = finalEndTime > new Date(startTime) ? finalEndTime : new Date(startTime)
      }

      if (isCliffVestableIDO(sale as CliffVestableIDO)) {
        const { cliffVestInfo } = sale as CliffVestableIDO
        const { vestList } = cliffVestInfo
        const startTime = new Date(vestList?.[0]?.date)
        finalStartTime = finalStartTime > startTime ? startTime : finalStartTime
        finalEndTime = finalEndTime > new Date(startTime) ? finalEndTime : new Date(startTime)
      }

      if (now <= new Date(finalEndTime)) {
        isLive = true
      }
      if (isLive) {
        break
      }
    }

    if (isLive) {
      filtered.push(project)
    }
    return filtered
  }, [])
  return filteredProjects
}

const isNodeSoldOut = (sale: PurchasableIDO) => {
  const { purchasePeriod } = sale
  const { totalPaymentReceived, maxTotalPurchasable, salePrice } = purchasePeriod

  if (totalPaymentReceived === 0) return false

  return totalPaymentReceived > 0 && totalPaymentReceived >= parseFloat((maxTotalPurchasable * salePrice).toFixed(5))
}

export const isNodeSaleEnded = (sale): boolean => {
  return isNodeSoldOut(sale)
}

export const isNodeSaleLive = (sale, sales, index): boolean => {
  if (isNodeSoldOut(sale)) return false

  if (index == 0 && !isNodeSoldOut(sale)) {
    return true
  }

  if (!isNodeSoldOut(sales[index - 1])) {
    return false
  }

  return true
}

export const isNodeSaleUpcoming = (sale, saleIndex, sales) => {
  if (saleIndex === 0) {
    return false
  }

  if (isNodeSaleLive(sales[saleIndex - 1], sales, saleIndex - 1)) {
    return true
  }

  return false
}

export const isSaleLive = (sale): boolean => {
  const now = new Date()

  let isLive = false
  let finalEndTime = new Date(0)
  const prices = {}

  if (isSubscribeableIDO(sale as SubscribeableIDO)) {
    const { subscribePeriod } = sale as SubscribeableIDO
    const { endTime } = subscribePeriod
    finalEndTime = finalEndTime > new Date(endTime) ? finalEndTime : new Date(endTime)
  }

  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const { purchasePeriod, saleId } = sale as PurchasableIDO
    const { endTime, salePrice } = purchasePeriod
    prices[saleId] = salePrice
    finalEndTime = finalEndTime > new Date(endTime) ? finalEndTime : new Date(endTime)
  }

  if (isClaimableIDO(sale as ClaimableIDO)) {
    const { claimPeriod } = sale as ClaimableIDO
    const { startTime } = claimPeriod

    finalEndTime = finalEndTime > new Date(startTime) ? finalEndTime : new Date(startTime)
  }

  if (isCliffVestableIDO(sale as CliffVestableIDO)) {
    const { cliffVestInfo } = sale as CliffVestableIDO
    const { vestList } = cliffVestInfo
    const startTime = new Date(vestList?.[0]?.date)
    finalEndTime = finalEndTime > new Date(startTime) ? finalEndTime : new Date(startTime)
  }

  if (now <= new Date(finalEndTime)) {
    isLive = true
  }
  return isLive
}

// for aethir whitelable sale
export const isSaleEnd = (sale) => {
  const now = new Date()

  let isEnd = false
  let finalEndTime = new Date(0)

  if (isSubscribeableIDO(sale as SubscribeableIDO)) {
    const { subscribePeriod } = sale as SubscribeableIDO
    const { endTime } = subscribePeriod
    finalEndTime = finalEndTime > new Date(endTime) ? finalEndTime : new Date(endTime)
  }

  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const { purchasePeriod } = sale as PurchasableIDO
    const { endTime } = purchasePeriod
    finalEndTime = finalEndTime > new Date(endTime) ? finalEndTime : new Date(endTime)
  }

  if (isClaimableIDO(sale as ClaimableIDO)) {
    const { claimPeriod } = sale as ClaimableIDO
    const { startTime } = claimPeriod

    finalEndTime = finalEndTime > new Date(startTime) ? finalEndTime : new Date(startTime)
  }

  if (isCliffVestableIDO(sale as CliffVestableIDO)) {
    const { cliffVestInfo } = sale as CliffVestableIDO
    const { vestList } = cliffVestInfo
    const startTime = new Date(vestList?.[0]?.date)
    finalEndTime = finalEndTime > new Date(startTime) ? finalEndTime : new Date(startTime)
  }

  if (now >= new Date(finalEndTime)) {
    isEnd = true
  }
  return isEnd
}

export const isSaleUpcoming = (sale) => {
  const now = new Date()

  let isUpcoming = false
  let finalStartTime = new Date(0)

  if (isSubscribeableIDO(sale as SubscribeableIDO)) {
    const { subscribePeriod } = sale as SubscribeableIDO
    const { startTime } = subscribePeriod
    finalStartTime = finalStartTime > new Date(startTime) ? finalStartTime : new Date(startTime)
  }

  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const { purchasePeriod } = sale as PurchasableIDO
    const { startTime } = purchasePeriod
    finalStartTime = finalStartTime > new Date(startTime) ? finalStartTime : new Date(startTime)
  }

  if (now <= new Date(finalStartTime)) {
    isUpcoming = true
  }

  return isUpcoming
}

export const isSaleParticipated = (
  sale: IDO,
  saleUserData: {
    userStakeWeight?: string
    paymentReceivedInWei?: string | BigNumber
    userStakedOnCurrentChain?: BigNumber
  },
): boolean => {
  let result = false

  if (isLinearVestableIDO(sale as LinearVestableIDO) && !isPurchaseableIDO(sale as PurchasableIDO)) {
    result = result || new BigNumber(sale.userAllocation ?? 0).isGreaterThan(0)
  }

  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    result = result || new BigNumber(saleUserData?.paymentReceivedInWei ?? 0).isGreaterThan(0)
  }
  if (isSubscribeableIDO(sale as SubscribeableIDO)) {
    if (saleUserData?.userStakedOnCurrentChain !== null && saleUserData?.userStakedOnCurrentChain !== undefined) {
      result = result || new BigNumber(saleUserData?.userStakedOnCurrentChain?.toLocaleString()).isGreaterThan(0)
    } else {
      result = result || new BigNumber(saleUserData?.userStakeWeight ?? 0).isGreaterThan(0)
    }
  }
  if (sale.isPrivate && isFixedAllocationIDO(sale as FixedAllocationIDO)) {
    result = true
  }
  return result
}

export const filterParticipatedOrWhitelistedProjects = (
  projects: Project[],
  saleUserDataMap: { [key: string]: { userStakeWeight?: string } },
): Project[] => {
  const filteredProjects = projects.reduce((filtered, project) => {
    let isTargetProject = false
    const currentProject = { ...project, sales: [] }
    for (let idx = 0; idx < project.sales.length; idx += 1) {
      const sale = project.sales[idx]
      const saleUserData = saleUserDataMap[sale.id]
      isTargetProject = isSaleParticipated(sale, saleUserData)
      if (isTargetProject) {
        currentProject.sales.push(sale)
      }
    }
    if (isTargetProject) {
      filtered.push(currentProject)
    }
    return filtered
  }, [])
  return filteredProjects
}

export default filterLive
