// STEP 2 Add new network

import tokens from './tokens'
import { Chain } from './types'

export default {
  syrup: {
    [Chain.BSC_TESTNET]: '0xEc4070F7Cb3Cb944Af729D8c23F3350dF24f1003',
    [Chain.BSC_MAINNET]: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    [Chain.POLYGON_MAINNET]: '',
    [Chain.MOONRIVER_MAINNET]: '',
    [Chain.AVAX_MAINNET]: '',
  },
  masterChef: {
    [Chain.BSC_TESTNET]: '0x683B1d0CC12c8862Ce102773B4E8fFd6370d3eDA',
    [Chain.BSC_MAINNET]: '0x3cafa8d80566a0dd500311ba7758772cec538e50',
    [Chain.POLYGON_MAINNET]: '',
    [Chain.MOONRIVER_MAINNET]: '',
    [Chain.AVAX_MAINNET]: '',
  },
  wgas: {
    [Chain.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    [Chain.BSC_TESTNET]: '0x094616f0bdfb0b526bd735bf66eca0ad254ca81f',
    [Chain.POLYGON_MAINNET]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    [Chain.MOONRIVER_MAINNET]: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
    [Chain.AVAX_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [Chain.ETH_MAINNET]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    [Chain.MOONBEAM_MAINNET]: '0xAcc15dC74880C9944775448304B263D191c6077F',
    [Chain.AURORA_MAINNET]: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
    [Chain.GOERLI]: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    [Chain.ARBITRUM_TESTNET]: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9',
    [Chain.ARBITRUM_MAINNET]: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    [Chain.OPTIMISM_MAINNET]: '0x4200000000000000000000000000000000000006',
    [Chain.OPTIMISM_TESTNET]: '0x4200000000000000000000000000000000000006',
    [Chain.NEON_DEVNET]: '0xf8ad328e98f85fccbf09e43b16dcbbda7e84beab',
    [Chain.OKC_MAINNET]: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
    [Chain.MANTLE]: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
    [Chain.HUMANODE_TESTNET]: '0x0000000000000000000000000000000000000802',
    [Chain.HUMANODE_MAINNET]: '0x0000000000000000000000000000000000000802',
    [Chain.OMNI_TESTNET]: '0x1212400000000000000000000000000000000003',
    [Chain.LINEA_TESTNET]: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
    [Chain.ARBITRUM_GOERLI]: '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
    [Chain.ARBITRUM_SEPOLIA]: '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73',
    [Chain.MANTA_MAINNET]: '0x0Dc808adcE2099A9F62AA87D9670745AbA741746',
    [Chain.OKX_TESTNET]: '0xa7b9c3a116b20bedddbe4d90ff97157f67f0bd97',
  },
  // Make sure we have multicall2 on each chain

  mulltiCall: {
    [Chain.BSC_MAINNET]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    [Chain.BSC_TESTNET]: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    [Chain.POLYGON_MAINNET]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d', // To confirm this is updated
    [Chain.AVAX_MAINNET]: '0x03debf8E506da9BaA859a9A349003C74F79A437C',
    [Chain.ETH_MAINNET]: '0x9695FA23b27022c7DD752B7d64bB5900677ECC21',
    [Chain.MOONRIVER_MAINNET]: '0x7a8eaD64B79C466d8A9Bcfd2a7B7BF938F9Cb542',
    [Chain.MOONBEAM_MAINNET]: '0x6477204E12A7236b9619385ea453F370aD897bb2',
    [Chain.AURORA_MAINNET]: '0x56E59989BCaf633ee60aBc4437F82A91A765f4f5',
    [Chain.GOERLI]: '0x2582429CddB4508E98325768aD771Da06F87ffE8',
    [Chain.ARBITRUM_TESTNET]: '0x5D6e06d3E154C5DBEC91317f0d04AE03AB49A273',
    [Chain.OPTIMISM_MAINNET]: '0x142e2feac30d7fc3b61f9ee85fccad8e560154cc',
    [Chain.OPTIMISM_TESTNET]: '0x2DC0E2aa608532Da689e89e237dF582B783E552C',
    [Chain.NEON_DEVNET]: '0x499Aef3f3822972f6C42D7C2Eaa13236B5e7673B',
    [Chain.OKC_MAINNET]: '0x19B6bc3e3f94409F043b30926807F712a76e930D',
    [Chain.ARBITRUM_MAINNET]: '0x7eCfBaa8742fDf5756DAC92fbc8b90a19b8815bF',
    [Chain.MANTLE]: '0x44FD8a4Ae3162dbAeDEcf8a7d67bd3eA160B28f4',
    [Chain.HUMANODE_TESTNET]: '0x5Ddc3D1b03aFA085390b86CC8666dF51b55c8a7a',
    [Chain.HUMANODE_MAINNET]: '0xca11bde05977b3631167028862be2a173976ca11',
    [Chain.OMNI_TESTNET]: '0x55Db8A1d45A04e553431cEc4772B73e298F3fEAd',
    [Chain.LINEA_TESTNET]: '0x9Cb8550c904cF3d713a24d3144c69EAaA96DC3E1',
    [Chain.ARBITRUM_GOERLI]: '0x108B25170319f38DbED14cA9716C54E5D1FF4623',
    [Chain.ARBITRUM_SEPOLIA]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [Chain.RONIN]: '0xca11bde05977b3631167028862be2a173976ca11',
    [Chain.SAIGON]: '0xca11bde05977b3631167028862be2a173976ca11',
    [Chain.MANTA_MAINNET]: '0x55BeE1bD3Eb9986f6d2d963278de09eE92a3eF1D',
    [Chain.OKX_TESTNET]: '0xe7f1F4a6a6B8aC6ecc535Dc41c7E79df36296634',
    [Chain.BASE_SEPOLIA]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  eth: {
    [Chain.BSC_MAINNET]: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    [Chain.BSC_TESTNET]: '0xebb6891b622c3938ebf5fdec3fcf0c697f8ae4e5',
    [Chain.MOONRIVER_MAINNET]: '0x98878b06940ae243284ca214f92bb71a2b032b8a', // Dummy Address
    [Chain.AVAX_MAINNET]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  },
  router: {
    [Chain.BSC_MAINNET]: '0x56f6ca0a3364fa3ac9f0e8e9858b2966cdf39d03',
    [Chain.BSC_TESTNET]: '0x4D3083cC8185a53284CD1ebaf44c86E1A6a8ACE6',
    [Chain.AVAX_MAINNET]: '0x16E1Cd2370752cc7A4aAd326fe2C1aae95B0c5E2',
    [Chain.AURORA_MAINNET]: '0xdE64A479f2b17bc5adBe906015840B7cE0022C8e',
    [Chain.ARBITRUM_MAINNET]: '0x39E48491b3A182C3F6b699281d74aF9f8a620CFa',
    [Chain.GOERLI]: '0x8C20a3F5C9A5926d4C83592c25317f602b385441',
    [Chain.OMNI_TESTNET]: '0x6Db8b088c4d41d622B44CD81B900bA690f2d496C',
    [Chain.HUMANODE_TESTNET]: '0xc654E932c4045E16A442FF4752b4CB8Ea213BA9d',
    [Chain.HUMANODE_MAINNET]: '0x8C20a3F5C9A5926d4C83592c25317f602b385441',
    [Chain.LINEA_TESTNET]: '0x1C70c66D287d02Ac43505e99F7b39ae65B022289',
    [Chain.ARBITRUM_GOERLI]: '0x6C77dCec416C0Ed9C88De08C86A7C0f5f3c75eee',
    [Chain.MANTA_MAINNET]: '0x12f5F39Da8d26D529d3AB5dD6D1C8af6EAdba3f8',
    [Chain.OKX_TESTNET]: '0x8C20a3F5C9A5926d4C83592c25317f602b385441',
  },
  factory: {
    [Chain.BSC_MAINNET]: '0x4233ad9b8b7c1ccf0818907908a7f0796a3df85f',
    [Chain.BSC_TESTNET]: '0xC6C9c55ceD9AA870FBe7e4633d88aAE484cca0C4',
    [Chain.AVAX_MAINNET]: '0x45603612891b6406A06854813e18443fC8ec7C73',
    [Chain.AURORA_MAINNET]: '0x45a3a315277Fbc1BCe0611c4398b32E0317Fd7c1',
    [Chain.GOERLI]: '0xAC1bE6b72b4b883BA45Fa69476660450Cfd9D5Dc',
    [Chain.ARBITRUM_MAINNET]: '0x9BF5852223000450AcD79ca8FDf6D7572a442d84',
    [Chain.OMNI_TESTNET]: '0x1044F1F2e208e213FBdFfeEbDc4eE0d194307438',
    [Chain.HUMANODE_TESTNET]: '0x2E146E6bDf9C42DED1ffa9EecbD65905926F9fdb',
    [Chain.HUMANODE_MAINNET]: '0xAC1bE6b72b4b883BA45Fa69476660450Cfd9D5Dc',
    [Chain.ARBITRUM_GOERLI]: '0xF52838e033a20295b69F8fBf75b00E0E5a482b4b',
    [Chain.LINEA_TESTNET]: '0xB1C3cB7cB1f6453334ec6798482299ad86A03ab2',
    [Chain.MANTA_MAINNET]: '0xb0aDD0739c692c40195E6fd1B287b55f389a18b9',
    [Chain.OKX_TESTNET]: '0xAC1bE6b72b4b883BA45Fa69476660450Cfd9D5Dc',
  },
  initCodeHash: {
    [Chain.BSC_MAINNET]: '0xfc84b622ba228c468b74c2d99bfe9454ffac280ac017f05a02feb9f739aeb1e4',
    [Chain.AVAX_MAINNET]: '0xfc84b622ba228c468b74c2d99bfe9454ffac280ac017f05a02feb9f739aeb1e4',
    [Chain.AURORA_MAINNET]: '0xfc84b622ba228c468b74c2d99bfe9454ffac280ac017f05a02feb9f739aeb1e4',
    [Chain.GOERLI]: '0x20bf34d7ad42a982d8700c48fa133ba50b84584b001fdda29b39238e494ff348',
    [Chain.BSC_TESTNET]: '0x20bf34d7ad42a982d8700c48fa133ba50b84584b001fdda29b39238e494ff348',
    [Chain.ARBITRUM_MAINNET]: '0x20bf34d7ad42a982d8700c48fa133ba50b84584b001fdda29b39238e494ff348',
    [Chain.OMNI_TESTNET]: '0xfc84b622ba228c468b74c2d99bfe9454ffac280ac017f05a02feb9f739aeb1e4',
    [Chain.HUMANODE_TESTNET]: '0xfc84b622ba228c468b74c2d99bfe9454ffac280ac017f05a02feb9f739aeb1e4',
    [Chain.HUMANODE_MAINNET]: '0xfc84b622ba228c468b74c2d99bfe9454ffac280ac017f05a02feb9f739aeb1e4',
    [Chain.MANTA_MAINNET]: '0x20bf34d7ad42a982d8700c48fa133ba50b84584b001fdda29b39238e494ff348',
    [Chain.OKX_TESTNET]: '0x20bf34d7ad42a982d8700c48fa133ba50b84584b001fdda29b39238e494ff348',
  },
  ensRegistrar: {
    [Chain.BSC_MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  },
  vidiaVesting: tokens.vidia.address,
}
