import { Box } from '@mui/material'
import ImageWithNetwork from 'componentsV2/ImageWithNetwork'
import Logo from 'componentsV2/Logo/Logo'
import Countdown from 'componentsV2/Countdown/Countdown'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import React from 'react'
import styled from 'styled-components'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Flex } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'

import { ManageRow, ManageState } from './utils'
import { ManageButton } from './Manage'

const MobileBox = styled(Box)`
  background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.main : theme.colorsV2.main2)};
  padding: 25px 30px 20px 30px;
  border-radius: 20px;
  margin: 20px 0;
}
`

const TextBox = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
`

export const ManageAction = {
  purchase: 'Purchase',
  staking: 'Staking',
  claim: 'Claim',
  airdrop: 'Airdrop',
  past: 'Past Project',
}

const ManageMobileCard: React.FC<{
  item: ManageRow
  onClick: (value) => void
  state: ManageState
}> = ({ item, onClick, state }) => {
  const {
    staked,
    stakingToken,
    projectImage,
    projectTitle,
    allocation,
    token,
    projectSubtitle,
    startTime,
    endTime,
    airdropNumber,
    isAirdrop,
  } = item
  const { t } = useTranslation()
  const currentDate = new Date()
  let title = t('Starts In')
  let color: 'text' | 'danger' | 'success' = 'text'
  let countdown = Math.floor((new Date(startTime).getTime() - currentDate.getTime()) / 1000)
  if (currentDate > new Date(startTime)) {
    title = t('Ends In')
    color = 'danger'
    countdown = Math.floor((new Date(endTime).getTime() - currentDate.getTime()) / 1000)
    color = countdown < 3600 ? 'danger' : 'text'
    if (state === ManageState.claim) {
      title = null
      color = 'success'
      countdown = null
    }
  }

  const theme = useTheme()

  return (
    <MobileBox onClick={onClick}>
      <Box display="flex">
        <Box marginRight={4}>
          <ImageWithNetwork
            alt="icon"
            src={projectImage}
            chainId={item.chainId}
            width="44px"
            chainWidth="20px"
            chainHeight="20px"
          />
        </Box>
        <TextBox>
          <TextBox>
            <IFTypography variant="body2" ifcolor="text" fontWeight="bold">
              {projectTitle}
            </IFTypography>
          </TextBox>
          <TextBox>
            <IFTypography variant="body2" ifcolor="textSecondary">
              {projectSubtitle}
            </IFTypography>
          </TextBox>
        </TextBox>
      </Box>
      <Box marginTop="20px" display="flex" justifyContent="space-between">
        <TextBox>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {t('Staked')}
          </IFTypography>
        </TextBox>
        <Box>
          <Flex mr="5px" alignItems="center">
            <Logo srcs={[`/images/tokens/${stakingToken.symbol}.png`]} width="16px" height="16px" />
            <Box marginLeft={2}>
              <IFTypography variant="body1" fontWeight="bold">
                {staked === 0 ? '-' : staked.toLocaleString()}
              </IFTypography>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box marginTop="10px" display="flex" justifyContent="space-between">
        <TextBox>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {state === ManageState.staking && 'EST. '}
            {t('Allocation')}
          </IFTypography>
        </TextBox>
        <Box display="flex" alignItems="center">
          <IFTypography variant="body1" fontWeight="bold">
            {allocation === 0 ? '-' : allocation.toLocaleString()}
          </IFTypography>
          <Box marginLeft={1}>
            <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
              {token.symbol}
            </IFTypography>
          </Box>
        </Box>
      </Box>
      <Box marginTop="10x" display="flex" justifyContent="space-between">
        <TextBox>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {state === ManageState.claim
              ? isAirdrop
                ? ManageAction.airdrop + (airdropNumber ? `#${airdropNumber} ` : '')
                : ManageAction.claim
              : state}{' '}
            {title}
          </IFTypography>
        </TextBox>
        <Box>
          <IFTypography variant="body2" fontWeight="bold" ifcolor={color}>
            {countdown ? (
              <Countdown style={{ fontSize: '14px', color: 'inherit' }} seconds={countdown} />
            ) : state === ManageState.claim ? (
              t('Available Now')
            ) : (
              ''
            )}
          </IFTypography>
        </Box>
      </Box>

      <Flex marginTop="20px" justifyContent="flex-end">
        {process.env.REACT_APP_ENABLE_QUICK_SIDEBAR ? (
          <ManageButton value={item} state={state} />
        ) : (
          <>
            <IFTypography
              variant="body2"
              ifcolor={theme.isDark ? 'textThirdly' : 'textSecondary'}
              fontFamily="Acre"
              style={{ textDecorationLine: 'underline' }}
            >
              {t('See detail')}
            </IFTypography>
            <IFTypography ifcolor={theme.isDark ? 'textThirdly' : 'textSecondary'}>
              <NavigateNextIcon fontSize="inherit" />
            </IFTypography>
          </>
        )}
      </Flex>
    </MobileBox>
  )
}

export default ManageMobileCard
