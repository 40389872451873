import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { SubscribeableIDO } from 'state/v2_types'
import { StakingCard, StakingMissedCard, StakingStartCard } from 'componentsV2/Card'

const Staking: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const getStakingState = useCallback(() => {
    const now = new Date()
    const { subscribePeriod, stepRequirement } = sale
    const { startTime, endTime } = subscribePeriod

    if (new Date(startTime) > now) {
      return <StakingStartCard sale={sale} />
    }
    if (new Date(endTime) > now) {
      return <StakingCard sale={sale} />
    }
    return <StakingMissedCard stepRequirement={stepRequirement} />
  }, [sale])

  return (
    <>
      <Box>{getStakingState()}</Box>
    </>
  )
}

export default Staking
