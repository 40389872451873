import React, { useMemo } from 'react'
import {
  AuctionIDOConfig,
  PrivateIDOConfig,
  PaidStakeSaleConfig,
  FreeStakeDropConfig,
  SubscribeableIDO,
  PurchasableIDO,
  LinearVestableIDO,
  IDO,
} from 'state/v2_types'
import { useSaleUserData } from 'state/idos/hooks'
import { isSubscribeableIDO, isPurchaseableIDO, isLinearVestableIDO } from 'state/idos/saleUtil'
import { isSaleParticipated } from 'state/idos/utils'
import { Box } from '@mui/material'
import styled, { useTheme } from 'styled-components'
import { useMatchBreakpoints } from 'uikit'
import ParticipatedBadge from './ParticipatedBadge'
import TopTitle from './TopTitle'
import Header from './Header'
import BottomInfo from './BottomInfo'
import ColorBorderBlankCard from '../ColorBorderBlankCard/ColorBorderBlankCard'
import SaleBriefCardMobile from './SaleBriefCardMobile'
import { SaleFiltered } from 'views/Project/Project'

const Container = styled(ColorBorderBlankCard)`
  padding: 40px 30px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: 40px;
  }

  & > div:last-child {
    flex-grow: 1;
  }
`

interface SaleBriefCardProps {
  sale: FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig | PaidStakeSaleConfig | SaleFiltered
}

const SaleBriefCard: React.FC<SaleBriefCardProps> = ({ sale }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const { colorsV2 } = useTheme()
  const userData = useSaleUserData(sale.id)
  const card = useMemo(() => {
    // const { isLive, isUpcoming, isEnd } = sale

    // const statusText = isEnd ? 'ENDED' : isUpcoming ? 'COMING SOON' : 'LIVE'
    const now = new Date()
    let saleStarted = false
    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      saleStarted = now >= new Date((sale as SubscribeableIDO).subscribePeriod.startTime)
    } else if (isPurchaseableIDO(sale as PurchasableIDO)) {
      saleStarted = now >= new Date((sale as PurchasableIDO).purchasePeriod.startTime)
    } else if (isLinearVestableIDO(sale as LinearVestableIDO)) {
      saleStarted = now >= new Date((sale as LinearVestableIDO).linearVestInfo.startTime)
    }

    const participated = isSaleParticipated(sale, userData)

    const [borderColor, showBorder] = saleStarted && participated ? [colorsV2.main4, true] : [null, false]
    return isMobile ? (
      <SaleBriefCardMobile borderColor={borderColor} showBorder={showBorder} participated={participated} sale={sale} />
    ) : (
      <Container borderColor={borderColor} showBorder={showBorder}>
        {participated && <ParticipatedBadge />}
        <TopTitle sale={sale} />
        {/* <Box>{statusText}</Box> */}
        <Header sale={sale} isMobile={false} />
        <Box marginTop="30px" />
        <BottomInfo sale={sale} />
      </Container>
    )
  }, [sale, userData, isMobile])

  return card
}

export default SaleBriefCard
