import React from 'react'
import styled from 'styled-components'
import { DownIcon } from 'uikit/components/Svg/index'
import { useAccountSidebar } from 'uikit/widgets/AccountSidebar/AccountSidebarContext'
import { Flex } from 'uikit'
import { walletLocalStorageKey } from 'uikit/widgets/WalletModal/config'
import { Box } from '@mui/material'

import { NetworkSelector } from 'componentsV2/NetworkSelector'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'

import { WalletFullWhiteIcon } from '../icons'
import Button from '../../../components/Button/Button'
import { Login, TokenBalance, KycInfo, Network } from '../../WalletModal/types'

interface Props {
  account?: string
  useGasBalance?: () => string
  gasToken?: string
  login: Login
  logout: () => void
  isMobile: boolean
  balances?: TokenBalance[]
  kycInfo?: KycInfo
  networks?: Network[]
  isNetworkUnavailable?: boolean
  showNetworks?: boolean
  showAccount?: boolean
}

const UserBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 8px;
`

const NetworkButton = styled(Button)<{ backgroundColor: string }>`
  background: ${({ theme, backgroundColor }) =>
    theme.isDark ? `${backgroundColor}99` : `${backgroundColor}80`} !important;
  padding: 0 6px 0 12px;
  border-radius: 47px 0px 0px 47px;
  color: ${({ theme }) => theme.colorsV2?.text};
  margin-right: 4px;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
  @media (max-width: 850px) {
    padding: 0px 6px 0px 10px;
  }
`

const AccountWrapper = styled.div`
  background: linear-gradient(90deg, #3580fe -2.21%, #a633fe 100%);

  border-radius: 12px;
  padding: 11px;
  display: flex;
  height: 44px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const BnbBalance = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: 0 9px 0 5px;
  }
  font-weight: 700;
`

const IconWrapper = styled(Flex)`
  color: ${({ theme }) => theme.colorsV2?.text};
  margin-right: 6px;
`

const selectorOptions = {
  desktopSelectorStyle: {
    containerWidth: '380px',
  },
}

const UserBlock: React.FC<Props> = ({
  account,
  useGasBalance,
  gasToken,
  login,
  logout,
  isMobile,
  balances = [],
  kycInfo,
  networks = [],
  isNetworkUnavailable,
  showNetworks,
  showAccount,
}) => {
  const walletID = window.localStorage.getItem(walletLocalStorageKey)
  // eslint-disable-next-line
  const gasBalance = useGasBalance && useGasBalance()
  const currentNetwork = networks.find((network) => network.isCurrent)
  const { openAccountSidebar } = useAccountSidebar()

  const renderButton = () => {
    return (
      <Box display="flex" justifyContent="center">
        <ConnectWalletButton showWalletIcon />
        {account && showAccount && (
          <UserBlockWrapper>
            {showNetworks && currentNetwork && (
              <NetworkSelector currentNetwork={currentNetwork} options={selectorOptions}>
                <NetworkButton backgroundColor={currentNetwork.backgroundColor}>
                  <Flex flexDirection="row" alignItems="center">
                    <img style={{ maxWidth: 'inherit' }} width="20px" alt="NetworkIcon" src={currentNetwork.iconSrc} />
                    <BnbBalance>
                      {!isMobile && gasBalance && gasBalance} {gasToken}
                    </BnbBalance>
                    {!isMobile && (
                      <IconWrapper>
                        <DownIcon color="currentColor" />
                      </IconWrapper>
                    )}
                  </Flex>
                </NetworkButton>
              </NetworkSelector>
            )}

            <AccountWrapper onClick={openAccountSidebar}>
              <Button scale="sm" style={{ padding: 0, background: 'transparent' }}>
                <WalletFullWhiteIcon />
              </Button>
            </AccountWrapper>
          </UserBlockWrapper>
        )}
      </Box>
    )
  }

  return <div>{renderButton()}</div>
}

export default React.memo(
  UserBlock,
  (prevProps, nextProps) => prevProps.account === nextProps.account && prevProps.balances === nextProps.balances,
)
