import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import { Button, Flex, SvgProps, useModalV2 } from 'uikit'
import { useLocation, useHistory } from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import * as IconModule from '../icons'
import { ThreeDotsIcon } from '../icons'
import { MobileNavProps } from '../types'
import MobileSettings from './MobileSettings'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

const BoxCont = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 97;
  background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2.main3 : theme.colorsV2.main2)};
  padding: 10px;
  backdrop-filter: blur(40px);

  .btnActive {
    background-color: ${({ theme }) => theme.colorsV2.dark};
  }
`

const ButtonStyled = styled(Button)`
  height: 67px;
  padding: 10px 20px;
  background: transparent;
  border-radius: 5px;
  font-size: 12px;
  max-width: 240px;
  flex: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorsV2.dark};
  }
  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 1;
  }
  .linkActive {
    background: ${({ theme }) => theme.colorsV2?.gradients.green2lightblue};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  color: ${({ theme }) => theme.nav.linkText};
`

const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> }

const MobileBottomMenu: React.FC<MobileNavProps> = ({ links }) => {
  const { isDark } = useTheme()
  const location = useLocation()
  const history = useHistory()
  const { brand } = useContext(CompanyContext)

  const mobileDismissHandlerWrapper = useRef<() => void>()
  const [onPresentMobileSelector, onDismissMobileSelector] = useModalV2({
    modal: <MobileSettings dismissHandlerWrapper={mobileDismissHandlerWrapper} />,
    options: {
      modalId: 'mobileMenu',
    },
  })
  mobileDismissHandlerWrapper.current = onDismissMobileSelector
  return (
    <>
      <BoxCont>
        {links
          .filter((item) => item?.label?.length > 0)
          .map(({ label, href, icon, pattern }) => {
            const isMainActive = location.pathname.match(pattern ?? href)
            const curTheme = isDark ? 'Dark' : 'Light'
            // const Icon = isMainActive ? Icons[`${icon}${curTheme}ActiveIcon`] : Icons[`${icon}${curTheme}Icon`]
            const Icon = isMainActive
              ? Icons[`${icon}${brand !== Brands.IF ? brand + curTheme : curTheme}ActiveIcon`] || Icons[``]
              : Icons[`${icon}${brand !== Brands.IF ? brand + curTheme : curTheme}Icon`]
            const iconElement = <Icon width="24px" mr="8px" style={{ margin: '0 0 8px 0' }} />
            return (
              <ButtonStyled
                key={href}
                className={isMainActive ? 'btnActive' : ''}
                onClick={() => history.push(`${href}`)}
              >
                <Flex flexDirection="column" alignItems="center">
                  {iconElement}
                  <span className={isMainActive ? 'linkActive' : ''}>{label}</span>
                </Flex>
              </ButtonStyled>
            )
          })}

        <ButtonStyled onClick={() => onPresentMobileSelector()}>
          <Flex flexDirection="column" alignItems="center">
            <ThreeDotsIcon style={{ margin: '7px 0 15px' }} />
            <p>More</p>
          </Flex>
        </ButtonStyled>
      </BoxCont>
    </>
  )
}

export default MobileBottomMenu
