import React from 'react'
import { useMatchBreakpoints } from 'uikit'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import styled from 'styled-components'
import { Box } from '@mui/material'
import { WalletWhiteIcon } from 'uikit/widgets/Menu/icons'

const StyledMobileConnectButton = styled.button`
  background: ${({ theme }) => theme.colorsV2.gradients.green2lightblue};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 30px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 10px;
  border: none;
  white-space: nowrap;
  height: 38px;
  padding: 0 20px;
  font-size: 14px;
`

const StyledMobileWrongButton = styled.button`
  background: ${({ theme }) => (theme.isDark ? 'rgba(88, 11, 11, 0.3)' : 'rgba(255, 178, 192, 0.3)')};
  color: ${({ theme }) => (theme.isDark ? '#FF8799' : '#ED756E')};

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  font-family: Acre;
  border-radius: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 10px;
  border: none;
  white-space: nowrap;
  height: 38px;
  padding: 0 20px;
  font-size: 14px;
`

const StyledMobileAccountButton = styled.button`
  background: ${({ theme }) => theme.common.connectButtonBackground};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  font-family: SFRounded, ui-rounded, 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  border-radius: 12px;
  font-weight: 700;
  color: ${({ theme }) => (theme.isDark ? 'rgb(255, 255, 255)' : '#232323')};
  padding: 4px 8px;
  border: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const ConnectWalletButton = (props) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  return (
    <Box style={{ ...props.style }} display="flex">
      <ConnectButton.Custom>
        {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading'
          const connected =
            ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated')

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                ...props.style,
              }}
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <StyledMobileConnectButton style={{ ...props.sx }} onClick={openConnectModal} type="button">
                      Connect Wallet {props.showWalletIcon && <WalletWhiteIcon />}
                    </StyledMobileConnectButton>
                  )
                }

                if (chain.unsupported) {
                  return (
                    <StyledMobileWrongButton style={{ ...props.sx }} onClick={openChainModal} type="button">
                      Wrong network
                    </StyledMobileWrongButton>
                  )
                }

                return (
                  <div style={{ display: 'flex', gap: 8, height: '100%' }}>
                    <StyledMobileAccountButton
                      onClick={openChainModal}
                      style={{ display: 'flex', alignItems: 'center' }}
                      type="button"
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 24,
                            height: 24,
                            borderRadius: 999,
                            overflow: 'hidden',
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? 'Chain icon'}
                              src={chain.iconUrl}
                              style={{ width: 24, height: 24 }}
                            />
                          )}
                        </div>
                      )}
                      {!isMobile && <Box marginLeft="8px">{chain.name}</Box>}
                      <KeyboardArrowDownIcon />
                    </StyledMobileAccountButton>

                    <StyledMobileAccountButton onClick={openAccountModal} type="button">
                      {account.displayName} <KeyboardArrowDownIcon />
                    </StyledMobileAccountButton>
                  </div>
                )
              })()}
            </div>
          )
        }}
      </ConnectButton.Custom>
    </Box>
  )
}

export default ConnectWalletButton
